import React from 'react';
import { useLocation } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import logoDark from '../../assets/images/logo-dark.png'

const OffCanvasMenu = (props) => {
    //const [iconToggle1, setIconToggle1] = useState(true)
    //const [iconToggle2, setIconToggle2] = useState(true)
    //const [iconToggle3, setIconToggle3] = useState(true)

    //const { parentMenu } = props;
    const location = useLocation();
    console.log(location.pathname)
    return (
        <>
            <div className='offcanvas-area'>
                <Link data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"><i className="ri-menu-line"></i></Link>
                <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                    <div className="offcanvas-header">
                        <Link to="/#"><img src={logoDark} alt="DarkLogo" /></Link>
                        <button type="button" className="offcanvasClose" data-bs-dismiss="offcanvas" aria-label="Close"><i className="ri-close-fill"></i></button>
                    </div>
                    <div className="offcanvas-body">
                        <nav className="navbar navbar-light">
                            <div className="w-100">
                                <div className="collapse navbar-collapse show" id="navbarSupportedContent">
                                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                        <li className="nav-item dropdown">
                                            <Link className={location.pathname === '/services' ? 'nav-link dropdown-toggle active' : 'nav-link dropdown-toggle'} to="/services#" id="navbarDropdown" >
                                                Productos
                                               
                                            </Link>
                                            <ul className="dropdown-menu show" aria-labelledby="navbarDropdown">
                                                <li>
                                                    <Link className={location.pathname === '/servicetran' ? 'dropdown-item active' : 'dropdown-item'} to="/servicetran#">helen Translate</Link>
                                                </li>
                                                <li>
                                                    <Link className={location.pathname === '/servicecloud' ? 'dropdown-item active' : 'dropdown-item'} to="/servicecloud#">helen Cloud</Link>
                                                </li>
                                                <li>
                                                    <Link className={location.pathname === '/servicecom' ? 'dropdown-item active' : 'dropdown-item'} to="/servicecom#">helen Community</Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="nav-item">
                                            <Link className={location.pathname === '/faq' ? 'nav-link active' : 'nav-link'} to="/faq#">Preguntas Frecuentes</Link>
                                        </li>
                                      
                                        <li className="nav-item dropdown ">
                                        <Link className={location.pathname === '/blog' ? 'nav-link active' : 'nav-link'} to="/blog#">Blog</Link>
                                        </li>
                                        <li className="nav-item dropdown ">
                                        <Link className={location.pathname === '/contact' ? 'nav-link active' : 'nav-link'} to="/contact#">Contacto</Link>
                                        </li>
                                        <li className="nav-item dropdown ">
                                        <Link className={location.pathname === '/soporte' ? 'nav-link active' : 'nav-link'} to="/soporte#">Soporte</Link>
                                        </li>


                                        {/*
                                        <li className="nav-item dropdown">
                                            <Link className={location.pathname === '/#' ? 'nav-link dropdown-toggle active' : 'nav-link dropdown-toggle'} to="/#" id="navbarDropdown" >
                                                Portal
                                               
                                            </Link>
                                            <ul className="dropdown-menu show" aria-labelledby="navbarDropdown">
                                                <li>
                                                    <Link className={location.pathname === '/login' ? 'dropdown-item active' : 'dropdown-item'} to="/login#">Inicio Sesión</Link>
                                                </li>
                                                <li>
                                                    <Link className={location.pathname === '/register' ? 'dropdown-item active' : 'dropdown-item'} to="/register#">Registro</Link>
                                                </li>
                                            </ul>
                                        */}
                                    </ul>
                                </div>
                            </div>
                        </nav>
                        <div className="offcanvas-icon-list">
                            <ul>
                                <li>
                                    <a href="https://www.facebook.com/people/HelenAi/100089439314118/?mibextid=ZbWKwL"><i className="ri-facebook-fill"></i></a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/_helen_ai_/?igshid=YmMyMTA2M2Y%3D"><i className="ri-instagram-fill"></i></a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/@helenAI-ky9cg/featured"><i className="ri-video-fill"></i></a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/mwlite/company/helenai"><i className="ri-linkedin-fill"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OffCanvasMenu;