import React from "react";
import video from '../../../assets/images/start.mp4'

const Splash = () => {
   
    return (
        <>
          <video
                loop
                muted
                autoPlay
                src={video}
                preload={'auto'}
                type={'video/mp4'}
                style={{width:'100%',height:'100%',aspectRatio:3/2}}
               >
            </video>
        </>
    );
};

export default Splash;