import React, { useEffect, useState } from 'react';
import Header from '../../Shared/Header';
import BannerSpa from './BannerSpa';
import Service from '../../Shared/ServiceSpaHome';
import AboutUs from '../../Shared/AboutUsSpa';
import Feature from '../../Shared/FeatureSpa';
import LatestBlog from '../../Shared/LatestBlogSpa';
import Apps from '../../Shared/Apps';
import Footer from '../../Shared/Footer'
import Splash from './Splash'
import 'reactjs-popup/dist/index.css';
import { Lightbox } from "react-modal-image";
import ImagenInicio from '../../../assets/images/ImagenInicio.png'
import ModalVideo from 'react-modal-video';


export const Modal = ({ src, alt, caption, onClose }) => {
  return (
    <div className="modal">
      <span className="close" onClick={onClose}>
        &times;
      </span>
      <img className="modal-content" src={src} alt={alt} />
      {caption.length > 0 && <div className="caption">{caption}</div>}
    </div>
  )
}

const HomeOne = () => {
  const [isOpen, setOpen] = useState(true)
  const [showElement, setShowElement] = React.useState(false);
  //const [isDesktop, setDesktop] = React.useState(true);
  const pantalla = window.innerWidth;
  console.log(pantalla)
  useEffect(() => {

    if (pantalla > 720) {
      setShowElement(false)
      setTimeout(function () {
        setShowElement(false);
      }, 3750);
    } else {//Ajustes movil
      //alert(navigator.platform)

      if (navigator.platform === 'iPhone') {
        document.getElementById("helenview").style.width = '270px';
        document.getElementById("helenview").style.height = '300px';
        document.getElementById("helenai").style.width = '270px';
        document.getElementById("helenai").style.height = '300px';

        document.getElementById("btnArrastre").style.marginLeft = '0%';
        document.getElementById("btnplay").style.marginLeft = '0%';
        document.getElementById("btnpause").style.marginLeft = '0%';
        document.getElementById("btnVelocity").style.marginLeft = '-2%';
      } else {

        document.getElementById("helenview").style.width = '270px';
        document.getElementById("helenview").style.height = '300px';
        document.getElementById("helenai").style.width = '270px';
        document.getElementById("helenai").style.height = '300px';

        document.getElementById("btnArrastre").style.marginLeft = '7%';
        document.getElementById("btnplay").style.marginLeft = '7%';
        document.getElementById("btnpause").style.marginLeft = '7%';
        document.getElementById("btnVelocity").style.marginLeft = '7%';

      }

    }

  }, []);

  useEffect(() => {

    //document.getElementById("miBoton").click();
    const elements = Array.from(document.getElementsByClassName("translate"));
    var statusHelen = document.getElementById("helenview").style.display;
    var statusHelenvisible = document.getElementById("helenview").style.visibility;

    if (statusHelen !== 'none') {
      elements.map(element => {
        element.classList.add('marcar');
        document.body.classList.add('manitas')
      })
    } else {
      elements.map(element => {
        element.classList.remove('marcar');
      })
    };

    if (statusHelenvisible === 'hidden') {
      elements.map(element => {
        document.getElementById("btnTranslate").style.visibility = 'visible';
        document.getElementById("helenai").style.visibility = 'visible';
        document.getElementById("helenview").style.visibility = 'visible';
      })
    };
  },
    [])



  //Fin
  return (

    <div>
      {showElement ? (
        <div style={{ backgroundColor: 'rgb(104,90,147)' }} ></div>
      ) : (<div><Header parentMenu='home' headerClass='sc-header-section' />
        <BannerSpa />


          


              <div className="banner-btn-area d-flex align-items-center">
                                    
              <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="vkKo2QwZgGU" onClose={() => setOpen(false)} />

            </div>


        <AboutUs />
        <Service />
        <Feature />
        <LatestBlog />
        <Apps />
        <Footer /> </div>)}{" "}


    </div>
  );
};

export default HomeOne;