import React, { useState } from 'react';
import { Fade } from 'react-reveal';
import ModalVideo from 'react-modal-video';
import { HashLink as Link } from 'react-router-hash-link';
import bannerGroup from '../../../assets/images/banner/banner1.png'
import bannerGroup2 from '../../../assets/images/banner/banner2.png'
import home1 from '../../../assets/glb/Home/home1.glb'
import home2 from '../../../assets/glb/Home/home2.glb'
//const home1="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/home1.glb"
//const home2="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/home2.glb"

export const Banner = () => {
    const [isOpen, setOpen] = useState(false)
    
     const translateClick = (model) => {
        var speed=document.getElementById("btnVelocity").innerText;
        //obtener el componente de modelviewer por medio del id
        var modelViewer = document.querySelector('#helenai');
        switch (model) {
            case 'home1':
                modelViewer.src=home1;
                break;
            case 'home2':
                modelViewer.src=home2;
                break;
        }
     modelViewer.timeScale = speed;
     modelViewer.play({repetitions: 1});
      }
    
    return (
        <>
            <section className="sc-banner-section-area3 sc-pt-130 sc-pb-50 ">
                <Fade top>
                    <div className="banner-circle-shape ">
                    </div>
                </Fade>
                <div className="container-fluid p-0">
                    <div className="row sc-banner-item d-flex align-items-center">
                    <div className="col-md-6">
                            <div className="banner-header-content">
                            <div>
                                <Fade left>
                                    <h1 onClick={()=>translateClick('home1')} className="banner-title p-z-idex white-color sc-mb-25 sc-sm-mb-15 translate">
                                        Comunicación al estilo y alcance de todos.</h1>
                                    <div onClick={()=>translateClick('home2')} className="banner-sub-title white-dark-color sc-mb-40 sc-sm-mb-25 translate" >
                                    helen es una inteligencia artificial creada para ayudar a las personas sordas,
                                    mediante la conversión de cualquier contenido digital a lengua de señas.
                                    </div>
                                </Fade>
                                </div>
                                <div className="banner-btn-area d-flex align-items-center">
                                    
                                    <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="k85XligGW84" onClose={() => setOpen(false)} />
                                    <Link className="primary-btn2 popup-video" onClick={() => setOpen(true)}>
                                        <i className="ri-play-fill"></i>
                                        <span>Introducción
                                        </span></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-4 col-lg-4" >
                            <div className="banner_image shake-y ">
                                <Fade left delay={700}>
                                    <img className="banner-1" src={bannerGroup} alt="Banner" />
                                </Fade>
                                <Fade right delay={900}>
                                    <img className="banner-2"  src={bannerGroup2} alt="Banner" />
                                </Fade>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="border-shape"></div>
                <div className="banner_frame d-none d-md-block">
                   
                </div>
            </section>
        </>
    );
};

export default Banner;