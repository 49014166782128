import React,{useRef} from 'react';
import { toast } from 'react-toastify';
import emailjs from 'emailjs-com';

const ContactInfo = () => {
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('helen_mail', 'template_x1996nq', form.current, 'LxV6Bqqn5-GrCPGVT')
          .then((result) => {
            
              toast('Hemos recibido tu mensaje, te contactaremos brevemente');
              form.current.reset();
          }, (error) => {
              console.log(error.text);
          });
      };

//https://www.emailjs.com/ , contrasena: HelenMail* 

  

    return (
        <>
            <section>

                <div className="sc-contact-pages-area sc-pt-140 sc-md-pt-70 sc-pb-20 sc-md-pb-10">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="sc-heading-area sc-mb-20">
                                    <h2 className="heading-title sc-mb-45">Solicite información sobre nuestra plataforma de servicios.</h2>
                                </div>
                                <div className="contact-page-service-box">
                                    <div className="contact_icon">
                                        <i className="ri-map-pin-fill"></i>
                                    </div>
                                    <div className="contact-service-box">
                                        <ul>
                                        <li><a href="https://www.google.com/maps/place/Mata+Redonda,+San+Jos%C3%A9/@9.9352781,-84.1209554,15z/data=!3m1!4b1!4m6!3m5!1s0x8fa0fca8bfb73e37:0x81473ea6db7a7c40!8m2!3d9.9334277!4d-84.1103725!16s%2Fm%2F05p4fg4?hl=es&entry=ttu"><span>Mata Redonda, San José, Costa Rica</span></a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="contact-page-service-box">
                                    <div className="contact_icon">
                                        <i className="ri-mail-fill"></i>
                                    </div>
                                    <div className="contact-service-box">
                                        <ul className="contact-list">
                                        <li><a href="mailto:hello@helenai.com">hello@helenai.com</a></li>
                                            <li><a href="mailto:support@helenai.com">support@helenai.com</a></li>
                                        </ul>
                                    </div>
                                    
                                </div>

                                <div className="contact-page-service-box">
                                    <div className="contact_icon">
                                        <i className="ri-whatsapp-fill"></i>
                                    </div>
                                    <div className="contact-service-box">
                                        <ul className="contact-list">
                                        <li><a href="https://wa.me/50684834461">+506 84834461</a></li>
                                        <li><a href="https://wa.me/50688705599">+506 88705599</a></li>
                                        </ul>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="contact-box sc-md-mb-10">
                                    <form ref={form} onSubmit={sendEmail}>
                                        <div className="login-form">
                                            <div className="form-box">
                                                <input className="from-control" type="text" id="name" name="name" placeholder="Nombre Completo" required autoComplete="off" />
                                            </div>
                                            <div className="form-box">
                                                <input className="from-control" type="email" id="email" name="email" placeholder="Correo Electrónico" required autoComplete="off" />
                                            </div>
                                            <div className="form-box">
                                                <input className="from-control" type="tel" id="phone" name="phone" placeholder="Teléfono" required autoComplete="off" />
                                            </div>
                                            <div className="form-box">
                                                <textarea id="message" name="message" placeholder="Mensaje" autoComplete="off" required></textarea>
                                            </div>
                                        </div>
                                        <div className="submit-button">
                                            <input className="submit-btn" type="submit" value="Enviar" />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <br></br>
            <br></br>
        </>
    );
};

export default ContactInfo;