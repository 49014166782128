import React from 'react';
import { Fade } from 'react-reveal';
import aboutImage from '../../assets/images/about/helenhi.png'
import home3 from '../../assets/glb/Home/home3.glb'
import home4 from '../../assets/glb/Home/home4.glb'
//const home3="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/home3.glb"
//const home4="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/home4.glb"

const AboutUs = () => {
    const translateClick = (model) => {
        var speed=document.getElementById("btnVelocity").innerText;
        //obtener el componente de modelviewer por medio del id
        var modelViewer = document.querySelector('#helenai');
        switch (model) {
            case 'home3':
                modelViewer.src=home3;
                break;
            case 'home4':
                modelViewer.src=home4;
                break;
        }
     modelViewer.timeScale = speed;
     modelViewer.play({repetitions: 1});
      }
    return (
        <>
            <section className="sc-about-area gray-bg sc-pt-140 sc-pb-180 sc-md-pt-80 sc-md-pb-70">
                <div className="container">
                    <div className="row align-items-center">
                        <Fade left>
                            <div className="col-lg-6">
                                <div className="image-area sc-md-mb-150">
                                    <div className="sc-about-image">
                                        <img className="about-image" style={{ width:'80%', height:'50%'}} src={aboutImage} alt="aboutImage" />
                                        <div className="circle-shape pulse"></div>
                                    </div>
                                </div>
                            </div>
                        </Fade>
                        <Fade right>
                            <div className="col-lg-6">
                                <div className="sc-about-item sc-pl-100">
                                    <div className="sc-heading-area sc-mb-30 sc-sm-mb-15">
                                        <h2 onClick={()=>translateClick('home3')} className="heading-title translate">Comunicación sin barreras.</h2>
                                    </div>
                                    <div onClick={()=>translateClick('home4')} className="description sc-mb-25 sc-sm-mb-15 translate">
                                    Utilice nuestra plataforma para convertir su negocio en accesible para personas sordas.
                                    <br></br>
                                    Nuestros productos brindan una solución para personas y empresas.
                                    </div>
                                </div>
                            </div>
                        </Fade>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AboutUs;