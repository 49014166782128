import React,{useRef}  from 'react';
import { Fade } from 'react-reveal';
import { toast } from 'react-toastify';
import shape1 from '../../assets/images/icons/shape_1.png'
import emailjs from 'emailjs-com';
const Apps = () => {

    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('helen_mail', 'template_rycb1ya', form.current, 'LxV6Bqqn5-GrCPGVT')
          .then((result) => {
            
              toast('Hemos recibido tu mensaje, te contactaremos brevemente');
              form.current.reset();
          }, (error) => {
              console.log(error.text);
          });
      };

    return (
        <>
            <section className="sc-apps-section-area sc-apps-bottom">
                <div className="container">
                    <div className="row">
                        <div className="apps-area d-flex align-items-center ">
                            <Fade left>
                                <div className="col-lg-6 ">
                                    <div className="apps-title ">
                                        <h2 className="heading-title white-color p-z-idex ">Conoce más sobre nuestros productos</h2>
                                    </div>
                                </div>
                            </Fade>
                            <Fade right>
                                <div className="col-lg-6">
                                    <form ref={form} onSubmit={sendEmail}>
                                        <div className="sc-form-inner p-z-idex d-flex align-items-center justify-content-end">
                                            <div className="apps-form">
                                                <input type="email" id="email" name="email" placeholder="Su email de contacto" required autoComplete="off" />
                                            </div>
                                            <div className="sc-primary-btn sc-md-pt-40">
                                                <button className="primary-btn2"><span>Información</span></button>
                                             
                                            </div>
                                            <div></div>
                                            
                                            </div>
                                    </form>
                                </div>
                            </Fade>
                            <div className="apps_shape_image">
                                <img className="shape-img_1" src={shape1} alt="Shape1" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Apps;