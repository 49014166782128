import React from 'react';
import { Fade } from 'react-reveal';
import featureImg7 from '../../../assets/images/feature/helen.png'
import productos2 from '../../../assets/glb/Productos/productos2.glb'
import productos3 from '../../../assets/glb/Productos/productos3.glb'
import productos4 from '../../../assets/glb/Productos/productos4.glb'
import productos5 from '../../../assets/glb/Productos/productos5.glb'
import productos6 from '../../../assets/glb/Productos/productos6.glb'
import productos7 from '../../../assets/glb/Productos/productos7.glb'
/*
const productos2="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/productos2.glb"
const productos3="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/productos3.glb"
const productos4="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/productos4.glb"
const productos5="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/productos5.glb"
const productos6="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/productos6.glb"
const productos7="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/productos7.glb"
*/

const ServiceFeature = () => {

    const translateClick = (model) => {
        var speed=document.getElementById("btnVelocity").innerText;
        //obtener el componente de modelviewer por medio del id
        var modelViewer = document.querySelector('#helenai');
        switch (model) {
            case 'productos2':
                modelViewer.src=productos2;
                break;
            case 'productos3':
                modelViewer.src=productos3;
                break;
            case 'productos4':
                modelViewer.src=productos4;
                break;
            case 'productos5':
                modelViewer.src=productos5;
                break;
            case 'productos6':
                modelViewer.src=productos6;
                break;
            case 'productos7':
                    modelViewer.src=productos7;
                    break;
        }
     modelViewer.timeScale = speed;
     modelViewer.play({repetitions: 1});
      }

    return (
        <>
            <section className="sc-feature-section-area5 sc-pt-175 sc-md-pt-115 sc-pb-140 sc-md-pb-70">
                <div className="container">
                    <div className="row align-items-center">
                        <Fade left>
                            <div className="col-lg-6">
                                <div className="sc-feature-image pulse sc-md-mb-50">
                                    <img className="feature_img" src={featureImg7} alt="Feature" />
                                    <div className="image-bg-circle"></div>
                                </div>
                            </div>
                        </Fade>
                        <Fade right>
                            <div className="col-lg-6">
                                <div className="sc-feature-item">
                                    <div className="sc-heading-area sc-mb-20">
                                       
                                        <h2 onClick={()=>translateClick('productos2')} className="heading-title sc-mb-25 translate">Un portafolio adaptado a sus necesidades.</h2>
                                        <div className="description sc-mb-25">
                                            <p className="translate" onClick={()=>translateClick('productos3')}>
                                            Trabajamos para brindar accesibilidad a la comunidad sorda. Contamos con soluciones tanto para personas como empresas.
                                            </p>
                                            <h5 className="translate" onClick={()=>translateClick('productos4')}>Para personas:</h5>
                                            <div className="feature_check">
                                        <ul className="translate">
                                            <li><i className="ri-checkbox-circle-fill" onClick={()=>translateClick('productos5')}></i>Aplicación sin costo para la comunidad.</li>
                                        </ul>
                                        <br></br>

                                            <h5 className="translate" onClick={()=>translateClick('productos6')}>Para empresas:</h5>
                                            <div className="feature_check translate" onClick={()=>translateClick('productos7')}>
                                        <ul>
                                            <li><i className="ri-checkbox-circle-fill "></i>Subscripciones mensuales o anuales por servicio.</li>
                                            <li><i className="ri-checkbox-circle-fill "></i>Pago por transacción.</li>
                                            <li><i className="ri-checkbox-circle-fill "></i>Licencias Corporativas.</li>
                                        </ul>
                                        </div>
                                    </div>
                                    </div>
                                        </div>
                                
                                    
                                   
                                </div>
                            </div>
                        </Fade>
                    </div>
                </div>
                
            </section>
        </>
    );
};

export default ServiceFeature;