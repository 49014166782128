import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import pagesBlogImg from '../../../assets/images/blog/art3.png'


const Blog2= () => {
    return (
        <>
            <section>
                <div className="sc-blog-details sc-pt-140 sc-md-pt-80 sc-pb-20 sc-md-pb-20">
                    <div className="container ">
                        <div className="row">
                            <div className='col-lg-2'></div>
                            <div className="col-lg-8">
                                <div className="blog-item">
                                    <div className="blog-image">
                                        <Link smooth to="#"><img src={pagesBlogImg} alt="Blog" /></Link>
                                    </div>
                                    <div className="blog-content p-0">
                                        <div className="blog_date">
                                            <ul>
                                                <li>Marzo</li>
                                                <li>2022</li>
                                            </ul>
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <h3 className="blog-title sc-pt-15 sc-mb-15 fw-bold">
                                        helen Community: herramientas de comunicación para las personas sordas.
                                        </h3>
                                    </div>
                                </div>
                                <div className="details-content-area sc-md-mb-50">
                                    <div className="details-content">
                                        <div className="description sc-mb-25">
                                        Está demostrado que las personas sordas (que representan al 5% de la población mundial), se ven expuestas frecuentemente al aislamiento, 
                                        dificultades para acceder al trabajo, estudiar, o incluso para realizar trámites ante instituciones públicas, debido a una falta de accesibilidad, a estereotipos y discriminación.
                                        <br></br>
                                        Esto además empeoró con la pandemia del COVID-19 debido al uso obligatorio de mascarillas que impiden a las personas sordas leer los labios de los demás.
                                        <br></br>
                                        <br></br>
                                        Ante ese escenario, helen Community es nuestra forma de apoyar a la comunidad sorda para que puedan comunicarse de manera más fácil con personas oyentes, 
                                        utilizando herramientas tecnológicas en forma de aplicaciones móviles.
                                        </div>
                                        <div className="description sc-mb-25">
                                        <h4>¿Qué es helen Community?</h4>
                                        La aplicación helen community funciona para teléfonos inteligentes. Estará disponible para sistemas Android y iOS.
                                        <br></br>
                                        
                                        Básicamente se trata de una caja de herramientas. Es decir, dentro de la aplicación hay varias utilidades que tienen el objetivo de apoyar la comunicación entre una persona sorda y una oyente. Esas herramientas tienen diferentes funcionalidades.
                                        <br></br>
                                        
                                        Por ejemplo, contiene una herramienta de transcripción de voz, de forma que la persona sorda pueda leer lo que la persona oyente dice. También tendrá conexión a helen Translate en los establecimientos comerciales o instituciones que lo tengan.
                                        </div>
                                        <div className="description sc-mb-25">
                                        <h4>Características de helen Community</h4>
                                        <li>Accesos rápidos configurables por la persona sorda, de modo que ella pueda programar mensajes frecuentes que utiliza en su comunicación diaria.
                                        </li>
                                        <br></br>
                                        <li>Un sintetizador de voz de forma que la persona sorda pueda comunicar un mensaje hablado a una persona oyente.
                                        </li>
                                        <br></br>
                                        <li>Otras herramientas que se añadirán próximamente a la aplicación.
                                        </li>
                                        Además, es fácil de registrarse, configurar y utilizar.
                                        <br></br><br></br>
                                        Esta caja de herramientas digital también es una forma de aportar a la construcción de un futuro más sostenible e inclusivo. Especialmente si tomamos en cuenta que, para 2050, está previsto que haya casi 2500 millones de personas con algún grado de pérdida de audición y que al menos 700 millones requerirán rehabilitación.
                                        </div>
                                        <div className="description sc-mb-25 style:{{textalign:'justify'}}">
                                        <h3>¿Cómo sumarse al desarrollo de helen Community?</h3>
                                        Nos encontramos en la etapa de prueba de la aplicación con algunas personas sordas, antes de lanzarla al mercado para llegar a una población mayor.
                                        <br></br>
                                        <br></br>
                                        Si usted quiere ser parte de las pruebas de helen Community, por favor envíenos un mensaje. ¡Nos encantará contar con su ayuda!
                                        </div>
                                    </div>
                             
                                </div>
                            </div>
                            <div className='col-lg-2'></div>
                        </div>
                        
                    </div>
                </div>
            </section>
        </>
    );
};

export default Blog2;