import React,{ useEffect} from 'react';
import Header from '../../Shared/Header';
import BreadCums from '../../Shared/BreadCums';
import FaqQuestion from './FaqQuestion';
import Apps from '../../Shared/Apps';
import Footer from '../../Shared/Footer';

const Faq = () => {

  
    
     //Copiar todo este useEffect a las Paginas donde haya que validar el subrayado
     useEffect(() => {
        document.getElementById("btnTranslate").style.visibility='visible';
        document.getElementById("helenai").style.visibility='visible';
        document.getElementById("helenview").style.visibility='visible';
        const elements = Array.from(document.getElementsByClassName("translate"));
        var statusHelen=document.getElementById("helenview").style.display;

        if(statusHelen!=='none'){
            elements.map(element=>{
              element.classList.add('marcar');
              document.body.classList.add('manitas')

          })
          }else{
            elements.map(element=>{
              element.classList.remove('marcar');

          })
          };
    },
    [])

    //Fin
    return (
        <>
            <Header
                parentMenu='pages'
                headerClass='sc-header-section inner-header-style'
                signUpBtn='inner-btn'
            />
            <BreadCums pageTitle='Preguntas Frecuentes' />
            <FaqQuestion />
            <Apps />
            <Footer />
        </>
    );
};

export default Faq;