import React from 'react';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import { HashLink as Link } from 'react-router-hash-link';
import blogImg1 from '../../assets/images/blog/art1.png'
import blogImg2 from '../../assets/images/blog/art2.png'
import blogImg3 from '../../assets/images/blog/art3.png'
import blogImg4 from '../../assets/images/blog/art4.png'
import bannerApps from '../../assets/images/bannerApps.png';
import playStore from '../../assets/images/playStore.png';
import appStore from '../../assets/images/appStore.png';

import home19 from '../../assets/glb/Home/home19.glb'
import home20 from '../../assets/glb/Home/home20.glb'
import home21 from '../../assets/glb/Home/home21.glb'
import home22 from '../../assets/glb/Home/home22.glb'
import home23 from '../../assets/glb/Home/home23.glb'
/*
const home19="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/home19.glb"
const home20="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/home20.glb"
const home21="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/home21.glb"
const home22="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/home22.glb"
const home23="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/home23.glb"
*/

const LatestBlog = () => {

    const translateClick = (model) => {
        var speed=document.getElementById("btnVelocity").innerText;
        //obtener el componente de modelviewer por medio del id
        var modelViewer = document.querySelector('#helenai');
        switch (model) {
            case 'home19':
                modelViewer.src=home19;
                break;
            case 'home20':
                modelViewer.src=home20;
                break;
            case 'home21':
                modelViewer.src=home21;
                break;
            case 'home22':
                modelViewer.src=home22;
                break;
            case 'home23':
                modelViewer.src=home23;
                break;
        }
     modelViewer.timeScale = speed;
     modelViewer.play({repetitions: 1});
      }

    return (
        <>
            <section className="sc-blog-area sc-pt-125 sc-md-pt-65 sc-sm-mb-30">
                <div className="container">
                    <div className="sc-heading-area sc-mb-55 sc-md-mb-45 text-center">
                        <h2 onClick={()=>translateClick('home19')} className="title translate">Blog</h2>
                    </div>
                    <div className="blog-item-area slider-item-area sc-slick-slide sc-slick-slides">
                        <div className="latest-blog-carousel-wrapper">
                            <Carousel className="latest-blog-carousel"
                                infinite={true}
                                draggable={true}
                                arrows={false}
                                pauseOnHover={true}
                                slidesToSlide={1}
                                swipeable={true}
                                autoPlay={true}
                                autoPlaySpeed={3000}
                                responsive={{
                                    desktop: {
                                        breakpoint: {
                                            max: 3000,
                                            min: 992
                                        },
                                        items: 3,
                                    },
                                    tablet: {
                                        breakpoint: {
                                            max: 991,
                                            min: 577
                                        },
                                        items: 2,
                                    },
                                    mobile: {
                                        breakpoint: {
                                            max: 576,
                                            min: 1
                                        },
                                        items: 1,
                                    }
                                }}>


                                <div className="blog-item text-center">
                                    <div className="blog-image">
                                        <Link to="/blog-3#"><img src={blogImg2} width="950" height="250" alt="Blog" /></Link>
                                    </div>
                                    <div className="blog-content">
                                        <div className="blog-list-menu">
                                            <ul className="blog-list">
                                                <li>
                                                    <i className="ri-calendar-line"></i> Febrero 2022
                                                </li>
                                            </ul>
                                        </div>
                                        <h4 onClick={()=>translateClick('home22')} className="blog-title sc-mt-20 translate"><Link to="/blog-3#">
                                            Software para convertir texto a LESCO: novedades tecnológicas al servicio de las personas sordas
                                            </Link></h4>
                                    </div>
                                </div>

                                <div className="blog-item text-center">
                                    <div className="blog-image">
                                        <Link to="/blog-3#"><img src={blogImg4} width="950" height="250" alt="Blog" /></Link>
                                    </div>
                                    <div className="blog-content">
                                        <div className="blog-list-menu">
                                            <ul className="blog-list">
                                                <li>
                                                    <i className="ri-calendar-line"></i> Noviembre 2023
                                                </li>
                                            </ul>
                                        </div>
                                        <h4 onClick={() => translateClick('')} className="blog-title sc-mt-20 translate"><Link to="/blog-4#">
                                        La LESCO como lengua materna de las personas sordas.
                                        </Link></h4>
                                    </div>
                                </div>

                                <div className="blog-item text-center">
                                    <div className="blog-image">
                                        <Link to="/blog-1#"><img src={blogImg1} width="950" height="250" alt="Blog" /></Link>
                                    </div>
                                    <div className="blog-content">
                                        <div className="blog-list-menu">
                                            <ul className="blog-list">
                                                <li>
                                                    <i className="ri-calendar-line"></i> Marzo 2022
                                                </li>
                                            </ul>
                                        </div>
                                        <h4 onClick={()=>translateClick('home21')} className="blog-title sc-mt-20 translate"><Link to="/blog-1#">
                                            ¿Conoce sobre las Apps para la atención de personas sordas?
                                            </Link></h4>
                                    </div>
                                </div>

                                <div className="blog-item text-center">
                                    <div className="blog-image">
                                        <Link to="/blog-2#"><img src={blogImg3} width="950" height="250" alt="Blog" /></Link>
                                    </div>
                                    <div className="blog-content">
                                        <div className="blog-list-menu">
                                            <ul className="blog-list">
                                                <li>
                                                    <i className="ri-calendar-line"></i>Marzo 2022
                                                </li>
                                            </ul>
                                        </div>
                                        <h4 onClick={()=>translateClick('home20')} className="blog-title sc-mt-20 translate"><Link to="/blog-2#">
                                        helen Community: herramientas de comunicación para las personas sordas
                                            </Link></h4>
                                    </div>
                                </div>
                            </Carousel>
                        </div>
                    </div>
                </div>
            </section>
            
        </>
    );
};

export default LatestBlog;