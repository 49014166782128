import React,{useEffect} from 'react';
import Header from '../../Shared/Header';
import BreadCums from '../../Shared/BreadCums';
import HubspotContactForm from './HubspotContactForm';

import Footer from '../../Shared/Footer';

const Contact = () => {
    useEffect(() => {
        document.getElementById("btnTranslate").style.visibility='hidden';
        document.getElementById("helenai").style.visibility='hidden';
        document.getElementById("helenview").style.visibility='hidden';
        document.body.classList.remove('manitas')
      
    },
    [])
   
    return (
        <>
            <Header
                headerClass='sc-header-section inner-header-style'
                signUpBtn='inner-btn'
            />
            <BreadCums pageTitle='Soporte' />
                             
<div className="sc-contact-pages-area sc-pt-50 sc-md-pt-70 sc-pb-20 sc-md-pb-10">

    <div className="container ">
   <center>
   <div className="sc-heading-area sc-mb-20">
                                </div>
            <div className="col-lg-6">
                <div className="contact-box sc-md-mb-10">
                <HubspotContactForm 
                        region="na1"
                        portalId="20018019"
                        formId='4e7a5f88-8cb1-4635-b3b5-74daf2cefa7f'
                        />                   
            </div>
            <br></br>
        </div>
        </center>
    </div>
</div>               
            <Footer />
        </>
    );
};

export default Contact;