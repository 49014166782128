import React from 'react';
import { useNavigate } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { toast } from 'react-toastify';

const LoginForm = () => {

    const navigate = useNavigate();
    const handleLogin = (event) => {
        event.preventDefault()
        event.target.reset()
        toast("Login Successfully")

        setTimeout(() => {
            navigate('/#');
        }, 1500);
    }

    return (
        <>
            <div className="sc-login-section sc-pt-120 sc-md-pb-70">
                <div className="sc-login-box">
                    <h4 className="sc-text text-center">Aún no se ha registrado para usar helenCloud? <Link className='primary-color' to="/signup#">Registro</Link></h4>
                    <form onSubmit={handleLogin}>
                        <div className="login-form">
                            <label className="login-label">Correo Electrónico</label>
                            <input className="from-control" type="email" id="form-email" name="email"  required autoComplete="off" />
                            <label className="login-label">Contraseña</label>
                            <input className="from-control" type="password" id="password" name="password"  autoComplete="off" required />
                        </div>
                        <div className="check-box d-flex justify-content-between">
                            <div className="login-password">
                                <Link to="#">Forget Password?</Link>
                            </div>
                        </div>
                        <div className="submit-btn text-center">
                            <input type="submit" value="Login" />
                        </div>
                    </form>

    
                </div>
            </div>
        </>
    );
};

export default LoginForm;