import React from 'react';
import preg1 from '../../../assets/glb/Preguntas/preguntas1.glb'
import preg2 from '../../../assets/glb/Preguntas/preguntas2.glb'
import preg3 from '../../../assets/glb/Preguntas/preguntas3.glb'
import preg4 from '../../../assets/glb/Preguntas/preguntas4.glb'
import preg5 from '../../../assets/glb/Preguntas/preguntas5.glb'
import preg6 from '../../../assets/glb/Preguntas/preguntas6.glb'
import preg7 from '../../../assets/glb/Preguntas/preguntas7.glb'
import preg8 from '../../../assets/glb/Preguntas/preguntas8.glb'
import preg9 from '../../../assets/glb/Preguntas/preguntas9.glb'
import preg10 from '../../../assets/glb/Preguntas/preguntas10.glb'
import preg11 from '../../../assets/glb/Preguntas/preguntas11.glb'
import preg12 from '../../../assets/glb/Preguntas/preguntas12.glb'

/*            
const preg1="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/preguntas1.glb"
const preg2="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/preguntas2.glb"
const preg3="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/preguntas3.glb"
const preg4="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/preguntas4.glb"
const preg5="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/preguntas5.glb"
const preg6="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/preguntas6.glb"
const preg7="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/preguntas7.glb"
const preg8="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/preguntas8.glb"
const preg9="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/preguntas9.glb"
const preg10="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/preguntas10.glb"
const preg11="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/preguntas11.glb"
const preg12="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/preguntas12.glb"
*/

const FaqQuestion = () => {
    const translateClick = (model) => {
        var speed=document.getElementById("btnVelocity").innerText;
        //obtener el componente de modelviewer por medio del id
        var modelViewer = document.querySelector('#helenai');
        switch (model) {
            case 'preg1':
                modelViewer.src=preg1;
                break;
            case 'preg2':
                modelViewer.src=preg2;
                break;
            case 'preg3':
                modelViewer.src=preg3;
                break;
            case 'preg4':
                modelViewer.src=preg4;
                break;
            case 'preg5':
                modelViewer.src=preg5;
                break;
            case 'preg6':
                modelViewer.src=preg6;
                break;
            case 'preg7':
                modelViewer.src=preg7;
                break;
            case 'preg8':
                modelViewer.src=preg8;
                break;
            case 'preg9':
                modelViewer.src=preg9;
                break;
            case 'preg10':
                modelViewer.src=preg10;
                break;
            case 'preg11':
                modelViewer.src=preg11;
                break;
            case 'preg12':
                modelViewer.src=preg12;
                break;
        }
     modelViewer.timeScale = speed;
     modelViewer.play({repetitions: 1});
      }

    return (
        <>
            <div className="sc-faq-pages-area sc-pt-130 sc-md-pt-70 sc-pb-10 sc-md-pb-10">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3">
                            <div className="sc-heading text-center sc-mb-60">
                            <h3 className="translate" onClick={()=>translateClick('preg1')}>Top de preguntas frecuentes</h3> 
                                <div className="description translate" onClick={()=>translateClick('preg2')}>
                                Anotamos las preguntas más frecuentes realizadas por nuestros clientes 
                                y se las compartimos, esperando que sean de ayuda para comprender mejor helen.
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 offset-lg-2">
                            <div className="accordion sc-faq-style" id="accordionExample">
                            <div className="accordion-item translate" onClick={()=>translateClick('preg3')}>
                
                <h2 className="accordion-header" id="headingOne">
                    <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne">
                    
                        ¿Cómo puedo conocer más sobre los productos?
                    </button>
                </h2>
                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                        <strong
                        >Puede conocer más sobre nuestro productos, solicitando información a nuestro equipo de soporte desde el formulario de contacto 
                        en la opción del menú princial, o simplemente complentar al final de la página con su correo electrónico y nuestro equipo le contactará.
                        </strong>
                    </div>
                </div>
                </div>

                <div className="accordion-item translate" onClick={()=>translateClick('preg4')}>
                                    <h2 className="accordion-header" id="headingTen">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseTen"
                                            aria-expanded="false"
                                            aria-controls="collapseTen">
                                           ¿A qué lenguaje de señas puedo traducir?
                                        </button>
                                    </h2>
                                    <div id="collapseTen" className="accordion-collapse collapse" aria-labelledby="headingTen" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <strong
                                            >Por ahora los productos helen disponibles son capaces de traducir de 
                                            Español a lengua de señas costarricense(LESCO).</strong>
                                        </div>
                                    </div>
                                </div>
           
            <div className="accordion-item translate" onClick={()=>translateClick('preg5')}>
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo"
                                            aria-expanded="false"
                                            aria-controls="collapseTwo">
                                           ¿Cómo puedo usar helen en mi vida cotiana?
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <strong
                                            >helen Community es una aplicación gratuita para dispositivos inteligentes tanto <a href="https://play.google.com/store/apps/details?id=com.helencommunity.helencommunity">Android </a> 
                                            como <a href="https://apps.apple.com/us/app/helen-community/id1576561202">iOS</a>,
                                            que integra herramientas de comunicación para apoyar a las personas sordas.</strong>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="accordion-item translate" onClick={()=>translateClick('preg6')}>
                                    <h2 className="accordion-header" id="headingThree">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree"
                                            aria-expanded="false"
                                            aria-controls="collapseThree">
                                            ¿Con qué dispositivos puedo usar helen?
                                        </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <strong
                                            >helen Community se encuentra disponible para dispositivos inteligentes Android y iOS.<br></br>
                                            helen translate funciona tanto en dispositivos móviles como computadoras y lo único que requiere 
                                            es de un navegador de internet.</strong>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item translate" onClick={()=>translateClick('preg7')}>
                                    <h2 className="accordion-header" id="headingFour">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseFour"
                                            aria-expanded="false"
                                            aria-controls="collapseFour">
                                            ¿Dónde puedo encontrar helen Community?
                                        </button>
                                    </h2>
                                    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <strong
                                            >helen Community se encuentra disponible en las distintas tiendas de aplicaciones tanto
                                            para dispositivos inteligentes <a href="https://play.google.com/store/apps/details?id=com.helencommunity.helencommunity">Android </a>  y <a href="https://apps.apple.com/us/app/helen-community/id1576561202">iOS</a>.</strong>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item translate" onClick={()=>translateClick('preg8')}>
                                    <h2 className="accordion-header" id="headingFive">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseFive"
                                            aria-expanded="false"
                                            aria-controls="collapseFive">
                                           ¿Cuáles opciones ofrece helen para clientes empresariales?
                                        </button>
                                    </h2>
                                    <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <strong
                                            >Dentro de nuestra plataforma de productos, helen Translate y helen Cloud son opciones para el ámbito empresarial.</strong>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item translate" onClick={()=>translateClick('preg9')}>
                                    <h2 className="accordion-header" id="headingSix">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseSix"
                                            aria-expanded="false"
                                            aria-controls="collapseSix">
                                            ¿Cuánto cuesta helen?
                                        </button>
                                    </h2>
                                    <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <strong
                                            >
                                            Contamos con la aplicación helen community completamente gratuita, enfocada en la comunidad sorda 
                                            y para empresas con subscripciones mensuales o anuales por servicio, pago por uso o transacción
                                            y licencias corporativas.
                                            </strong>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item translate" onClick={()=>translateClick('preg10')}>
                                    <h2 className="accordion-header" id="headingSeven">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseSeven"
                                            aria-expanded="false"
                                            aria-controls="collapseSeven">
                                            ¿Cuáles beneficios me ofrece helen?
                                        </button>
                                    </h2>
                                    <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <strong
                                            >
                                            Ayudamos a que tus productos y servicios sean accesibles a usuarios sordos.<br></br>
                                            Adaptamos nuestros productos a las necesidades y preferencias del cliente.
                                            Garantizamos productos intuitivos y de fácil uso.<br></br>
                                            </strong>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item translate" onClick={()=>translateClick('preg11')}>
                                    <h2 className="accordion-header" id="headingEight">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseEight"
                                            aria-expanded="false"
                                            aria-controls="collapseEight">
                                            ¿En que áreas de negocio puedo implementar helen?
                                        </button>
                                    </h2>
                                    <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <strong
                                            >
                                            helen se encuentra enfocado en brindar atención y servicio de calidad a clientes sordos, 
                                            en áreas de salud, banca y finanzas. 
                                            </strong>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item translate" onClick={()=>translateClick('preg12')}>
                                    <h2 className="accordion-header" id="headingNine">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseNine"
                                            aria-expanded="false"
                                            aria-controls="collapseNine">
                                            ¿Cómo puedo probar los productos para empresas?
                                        </button>
                                    </h2>
                                    <div id="collapseNine" className="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <strong
                                            >
                                            Para probar nuestros productos para empresas, puede contactarnos mediante 
                                            el formulario de contacto o escribir directamente al correo electrónico support@helenai.com .
                                            </strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FaqQuestion;