import React,{useEffect} from 'react';
import Header from '../../Shared/Header';
import BreadCums from '../../Shared/BreadCums';
import ContactInfo from './ContactInfo';

import Footer from '../../Shared/Footer';

const Contact = () => {
    useEffect(() => {
        document.getElementById("btnTranslate").style.visibility='hidden';
        document.getElementById("helenai").style.visibility='hidden';
        document.getElementById("helenview").style.visibility='hidden';
        document.body.classList.remove('manitas')
      
    },
    [])
   
    return (
        <>
            <Header
                headerClass='sc-header-section inner-header-style'
                signUpBtn='inner-btn'
            />
            <BreadCums pageTitle='Contacto' />
            <ContactInfo />
            <Footer />
        </>
    );
};

export default Contact;