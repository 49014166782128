import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import pagesBlogImg from '../../../assets/images/blog/art4.png'
import pagesBlogImg2 from '../../../assets/images/blog/art4_2.png'
import pagesBlogImg3 from '../../../assets/images/blog/art4_3.png'

const Blog4 = () => {
    return (
        <>
            <section>
                <div className="sc-blog-details sc-pt-140 sc-md-pt-80 sc-pb-20 sc-md-pb-20">
                    <div className="container ">
                        <div className="row">
                            <div className='col-lg-2'></div>
                            <div className="col-lg-8">
                                <div className="blog-item">
                                    <div className="blog-image">
                                        <Link smooth to="#"><img src={pagesBlogImg} alt="Blog" /></Link>
                                    </div>
                                    <div className="blog-content p-0">
                                        <div className="blog_date">
                                            <ul>
                                                <li>Octubre</li>
                                                <li>2023</li>
                                            </ul>
                                        </div>
                                        <br></br>
                                        <h3 className="blog-title sc-pt-15 sc-mb-15 fw-bold">
                                            La LESCO como lengua materna de las personas sordas
                                        </h3>
                                    </div>
                                </div>
                                <div className="details-content-area sc-md-mb-50">
                                    <div className="details-content">
                                        <div className="description sc-mb-25">

                                            En Costa Rica, la LESCO, desempeña un papel fundamental en la inclusión y la comunicación efectiva de las personas sordas y con discapacidad auditiva. Esta lengua de señas local, reconocida oficialmente desde el año 2006, ha dado pasos significativos apoyando al país en el compromiso por promover la igualdad de derechos y la inclusión social de las personas sordas a través del reconocimiento y promoción de esta lengua.

                                            <br></br>
                                            <br></br>

                                            En un esfuerzo por promover la igualdad de derechos y la inclusión social, Costa Rica ha adoptado medidas significativas en relación con la lengua de señas costarricense (LESCO), reconociendo su importancia tanto como un patrimonio cultural y lingüístico, así como una herramienta fundamental para la comunicación de la comunidad de personas sordas e incluso oyentes, como menciona el Bach. Christian Ramirez Valerio; “También en el campo de la interpretación de la LESCO este conocimiento es importante puesto que nos permite cómo usar y manejar la interpretación de expresiones de una lengua de señas a una lengua oral, y viceversa” (Ramirez, C, 2013, p.10).

                                            <br></br>


                                        </div>

                                        <h4 className="blog-title sc-pt-15 sc-mb-15 fw-bold">
                                            Leyes en Costa Rica
                                        </h4>



                                        <div className="description sc-mb-25">
                                            A través de las Leyes n.° 9822 y n.° 9049, promulgadas en 2020 y 2012 respectivamente, Costa Rica ha dado pasos cruciales para garantizar que las personas sordas tengan acceso en igualdad de condiciones a diversos aspectos de la vida cotidiana, incluyendo la educación, la información, el empleo y la participación activa en la sociedad. Estas leyes no solo cumplen con los estándares internacionales de derechos humanos y discapacidad, sino que también enriquecen la diversidad cultural y lingüística del país al reconocer y preservar la riqueza de la LESCO como parte integral de su identidad cultural.

                                            <br></br>
                                            <br></br>
                                            La Ley n.° 9822, publicada en el Alcance n.°178 a La Gaceta n.° 173 de 16 de junio de 2020, tiene como objeto reconocer y promover la lengua de señas costarricense (LESCO) como lengua de las personas sordas en Costa Rica que libremente decidan utilizarla, así como el uso de los medios de apoyo a la comunicación oral.
                                            <br></br>
                                            <br></br>
                                            La ley reconoce la LESCO como patrimonio cultural y lingüístico de la comunidad de personas sordas y la incorpora al sistema plurilingüístico costarricense. Además, se establecen medidas para garantizar que las personas sordas tengan acceso en igualdad de condiciones con los demás al entorno físico, el transporte, la información, la educación y las comunicaciones. Según el Artículo 7 de la Ley n.° 9822 "RECONOCIMIENTO Y PROMOCIÓN DE LA LENGUA DE SEÑAS COSTARRICENSE (LESCO)" (16 de junio de 2020), se establece que El Consejo Superior de Educación Pública y el Ministerio de Educación Pública (MEP) incluirán en sus planes de estudio la enseñanza y el aprendizaje de la lesco como asignatura optativa para estudiantes oyentes, facilitando de esta manera la inclusión social de estudiantes sordos usuarios de la Lesco y fomentando valores de igualdad y respeto a la diversidad lingüística y cultural.(Ley n.°9822, Artículo 7).
                                            <br></br>
                                            <br></br>
                                            La Ley n.° 9049, que entró en vigencia en el año 2012, reconoce la LESCO como lengua materna de la comunidad sorda. Esta legislación obliga a las instituciones públicas y privadas a garantizar a las personas sordas su uso y a que el Ministerio de Educación Pública (MEP) lo incorpore en sus programas educativos y apoye su divulgación.

                                        </div>

                                        <div className="blog-image">
                                            <Link smooth to="#"><img style={{ width:800, height:600}} src={pagesBlogImg2} alt="Blog" /></Link>
                                        </div>

                                        <h4 className="blog-title sc-pt-15 sc-mb-15 fw-bold">
                                            Las Inteligencias Artificiales
                                        </h4>

                                        <div className="description sc-mb-25">
                                            La UNESCO, en cuanto a las inteligencias artificiales (IA) como helenAI, ha reconocido el potencial de estas para el bien social y la promoción de los Objetivos de Desarrollo Sostenible (ODS). Los rápidos avances tecnológicos en IA están transformando disciplinas, economías e industrias. Mientras estas sean utilizadas para el beneficio de la humanidad, la inclusión y se basen en la paz, estas acelerarán el logro de los ODS. En base a la UNESCO “La IA tiene un enorme potencial para el bien social y la promoción de la consecución de los ODS si se desarrolla de una manera que beneficie a la humanidad, respete las normas y estándares mundiales y esté anclada en la paz y el desarrollo.”(unesco.org, 2023)


                                        </div>

                                        <div className="blog-image" >
                                            <Link smooth to="#"><img style={{ width:800, height:600}} src={pagesBlogImg3} alt="Blog" /></Link>
                                        </div>

                                        <h4 className="blog-title sc-pt-15 sc-mb-15 fw-bold">
                                            Referencias
                                        </h4>

                                        Ramírez, C. (2007). Estructura de la cláusula en la lengua de señas costarricense (LESCO). Recuperado de UNESCO. (2023). Inteligencia artificial (IA). Recuperado de <a href="https://cultura-sorda.org/wp-content/uploads/2015/03/ChRamirez-EstructuraClausulaLESCO-2007.pdf" target="_blank">enlace</a>

                                        <br></br>
                                        <br></br>

                                        Asamblea Legislativa de la República de Costa Rica. (2020). Ley n.° 9822: Reconocimiento y promoción de la lengua de señas costarricense (LESCO). Recuperado de UNESCO. (2023). Inteligencia artificial (IA). Recuperado de <a href="https://www.tse.go.cr/pdf/normativa/Ley9822_Reconocimiento_y_promocion_de_la_lengua_LESCO.pdf" target="_blank" >enlace</a>

                                        <br></br>
                                        <br></br>

                                        UNESCO. (2023). Inteligencia artificial (IA). Recuperado de <a href="https://www.unesco.org/es/artificial-intelligence" target="_blank" >enlace</a>


                                    </div>

                                </div>
                            </div>
                            <div className='col-lg-2'></div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    );
};

export default Blog4;