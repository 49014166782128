import React ,{ useEffect }   from 'react';
import Header from '../../Shared/Header';
import BreadCums from '../../Shared/BreadCums';
import BlogNews from './BlogNews';
import Apps from '../../Shared/Apps';
import Footer from '../../Shared/Footer';

const Blog = () => {
    useEffect(() => {
        document.getElementById("btnTranslate").style.visibility='hidden';
    document.getElementById("helenai").style.visibility='hidden';
    document.getElementById("helenview").style.visibility='hidden';
    document.body.classList.remove('manitas')
      
    },
    [])
    
    return (
        <>
            <Header
                parentMenu='blog'
                headerClass='sc-header-section inner-header-style'
                signUpBtn='inner-btn'
            />
            <BreadCums pageTitle='Blog' />
            <BlogNews />
            <Apps />
            <Footer />
        </>
    );
};

export default Blog;