import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import pagesBlogImg1 from '../../../assets/images/blog/art2.png'
import pagesBlogImg2 from '../../../assets/images/blog/art1.png'
import pagesBlogImg3 from '../../../assets/images/blog/art3.png'
import pagesBlogImg4 from '../../../assets/images/blog/art4.png'

const BlogNews = () => {
    return (
        <>
            <div className="sc-blog-pages-area sc-pt-140 sc-md-pt-80 sc-pb-20 sc-md-pb-20">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-2"></div>
                        <div className="col-lg-8">

                            <div className="blog-item sc-mb-30">
                                <div className="blog-image">
                                    <Link to="#"><img style={{ width:800, height:650}} src={pagesBlogImg4} alt="Blog" /></Link>
                                </div>
                                <div className="blog-content text-center">
                                    <div className="blog_date">
                                        <ul>
                                            <li>Octubre</li>
                                            <li>2023</li>
                                        </ul>
                                    </div>

                                    <h3 className="blog-title sc-pt-15 sc-mb-15 fw-bold">
                                        <Link to="/blog-4#">La LESCO como lengua materna de las personas sordas</Link>
                                    </h3>
                                    <div className="description sc-mb-20">
                                    En Costa Rica, la lengua de señas costarricense (LESCO) es fundamental para la inclusión de personas sordas. Dos leyes, la n.° 9049 de 2012 y la n.° 9822 de 2020, garantizan derechos y promueven la LESCO como patrimonio cultural. La UNESCO reconoce el potencial de la inteligencia artificial como helenAI para el bien social si se utiliza éticamente.
                                    </div>
                                    <Link to="/blog-4#" className="readmore">Leer</Link>
                                </div>
                            </div>

                            <div className="blog-item sc-mb-30">
                                <div className="blog-image">
                                    <Link to="#"><img style={{ width:800, height:650}} src={pagesBlogImg2} alt="Blog" /></Link>
                                </div>
                                <div className="blog-content text-center">
                                    <div className="blog_date">
                                        <ul>
                                            <li>Marzo</li>
                                            <li>2022</li>
                                        </ul>
                                    </div>

                                    <h3 className="blog-title sc-pt-15 sc-mb-15 fw-bold">
                                        <Link to="/blog-1#">¿Conoce sobre las Apps para la atención de personas sordas?</Link>
                                    </h3>
                                    <div className="description sc-mb-20">
                                    La tecnología y la Inteligencia Artificial se han convertido en aliadas de las empresas y organizaciones que quieren ser más inclusivas y ofrecer un servicio integral a todas las personas.
                                    </div>
                                    <Link to="/blog-1#" className="readmore">Leer</Link>
                                </div>
                            </div>
                            <div className="blog-item sc-mb-30">
                                <div className="blog-image">
                                    <Link to="#"><img style={{ width:800, height:650}} src={pagesBlogImg3} alt="Blog" /></Link>
                                </div>
                                <div className="blog-content text-center">
                                    <div className="blog_date">
                                        <ul>
                                            <li>Marzo</li>
                                            <li>2022</li>
                                        </ul>
                                    </div>

                                    <h3 className="blog-title sc-pt-15 sc-mb-15 fw-bold">
                                        <Link to="/blog-2#">helen Community: herramientas de comunicación para las personas sordas</Link>
                                    </h3>
                                    <div className="description sc-mb-20">
                                    helen Community es nuestra forma de apoyar a la comunidad sorda para que puedan comunicarse de manera más fácil con personas oyentes, 
                                    utilizando herramientas tecnológicas en forma de aplicaciones móviles.
                                    </div>
                                    <Link to="/blog-2#" className="readmore">Leer</Link>
                                </div>
                            </div>
                            <div className="blog-item sc-mb-30">
                                <div className="blog-image">
                                    <Link to="#"><img style={{ width:800, height:500}} src={pagesBlogImg1} alt="Blog" /></Link>
                                </div>
                                <div className="blog-content text-center">
                                    <div className="blog_date">
                                        <ul>
                                            <li>Febrero</li>
                                            <li>2022</li>
                                        </ul>
                                    </div>

                                    <h3 className="blog-title sc-pt-15 sc-mb-15 fw-bold">
                                        <Link to="/blog-3#">Software para convertir texto a LESCO: novedades tecnológicas al servicio de las personas sordas</Link>
                                    </h3>
                                    <div className="description sc-mb-20">
                                    helen Translate es una aplicación de Inteligencia Artificial con múltiples funcionalidades y creada por costarricenses para ayudar a personas sordas y con problemas de audición.
                                    </div>
                                    <Link to="/blog-3#" className="readmore">Leer</Link>
                                </div>
                            </div>

                            
                        </div>
                        
                    </div>
                    <div className="spria-navigation text-center sc-md-pt-70">
                        <ul>
                            <li><Link to="#" className="active">1</Link></li>

                        </ul>
                        
                    </div>
                </div>
            </div>
        </>
    );
};

export default BlogNews;