import React from 'react';
import { Fade } from 'react-reveal';
import logo from '../../assets/images/service/Community.png'
import translate from '../../assets/images/service/community.gif'
import bannerApps from '../../assets/images/bannerApps.png';
import playStore from '../../assets/images/playStore.png';
import appStore from '../../assets/images/appStore.png';
import huaweiStore from '../../assets/images/huawei.png';

import com1 from '../../assets/glb/HelenCommunity/community1.glb'
import com2 from '../../assets/glb/HelenCommunity/community2.glb'
import com3 from '../../assets/glb/HelenCommunity/community3.glb'
import com4 from '../../assets/glb/HelenCommunity/community4.glb'
import com5 from '../../assets/glb/HelenCommunity/community5.glb'
import com6 from '../../assets/glb/HelenCommunity/community6.glb'
import com7 from '../../assets/glb/HelenCommunity/community7.glb'
import com8 from '../../assets/glb/HelenCommunity/community8.glb'
import com9 from '../../assets/glb/HelenCommunity/community9.glb'
import com10 from '../../assets/glb/HelenCommunity/community10.glb'
import com11 from '../../assets/glb/HelenCommunity/community11.glb'
import com12 from '../../assets/glb/HelenCommunity/community12.glb'
import com13 from '../../assets/glb/HelenCommunity/community13.glb'
import com14 from '../../assets/glb/HelenCommunity/community14.glb'
import com15 from '../../assets/glb/HelenCommunity/community15.glb'
import com16 from '../../assets/glb/HelenCommunity/community16.glb'
import com17 from '../../assets/glb/HelenCommunity/community17.glb'
import com18 from '../../assets/glb/HelenCommunity/community18.glb'
import com19 from '../../assets/glb/HelenCommunity/community19.glb'
import com20 from '../../assets/glb/HelenCommunity/community20.glb'
/*
const com1="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/community1.glb"
const com2="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/community2.glb"
const com3="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/community3.glb"
const com4="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/community4.glb"
const com5="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/community5.glb"
const com6="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/community6.glb"
const com7="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/community7.glb"
const com8="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/community8.glb"
const com9="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/community9.glb"
const com10="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/community10.glb"
const com11="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/community11.glb"
const com12="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/community12.glb"
const com13="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/community13.glb"
const com14="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/community14.glb"
const com15="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/community15.glb"
const com16="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/community16.glb"
const com17="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/community17.glb"
const com18="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/community18.glb"
const com19="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/community19.glb"
const com20="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/community20.glb"
*/

const ServiceFeature = (props) => {
    const translateClick = (model) => {
        var speed=document.getElementById("btnVelocity").innerText;
        //obtener el componente de modelviewer por medio del id
        var modelViewer = document.querySelector('#helenai');
        switch (model) {
            case 'com1':
                modelViewer.src=com1;
                break;
            case 'com2':
                modelViewer.src=com2;
                break;
            case 'com3':
                modelViewer.src=com3;
                break;
            case 'com4':
                modelViewer.src=com4;
                break;
            case 'com5':
                modelViewer.src=com5;
                break;
            case 'com6':
                modelViewer.src=com6;
                break;
            case 'com7':
                modelViewer.src=com7;
                break;
            case 'com8':
                modelViewer.src=com8;
                break;
            case 'com9':
                modelViewer.src=com9;
                break;
            case 'com10':
                modelViewer.src=com10;
                break;
            case 'com11':
                modelViewer.src=com11;
                break;
            case 'com12':
                modelViewer.src=com12;
                break;
            case 'com13':
                modelViewer.src=com13;
                break;
            case 'com14':
                modelViewer.src=com14;
                break;
            case 'com15':
                modelViewer.src=com15;
                break;
            case 'com16':
                modelViewer.src=com16;
                break;
            case 'com17':
                modelViewer.src=com17;
                break;
            case 'com18':
                modelViewer.src=com18;
                break;
            case 'com19':
                modelViewer.src=com19;
                break;
            case 'com20':
                modelViewer.src=com20;
                break;
        }
     modelViewer.timeScale = speed;
     modelViewer.play({repetitions: 1});
      }

    return (
        <>
            <section className="sc-feature-section-area5 sc-pt-175 sc-md-pt-115 sc-pb-140 sc-md-pb-70">
                <div className="container">
                    <div className="sc-heading-area text-center ">
                        <img className="banner-1" src={logo} width="270" height="240" viewBox="0 0 70 48" fill="none" />
                    </div>
                    <div className="row"><div className="col-md-6 offset-md-3">
                        <div className="sc-heading-area text-center sc-mb-60">
                        <br></br>
                        <br></br>
                        <br></br>
                            <h2 className="heading-title translate" onClick={()=>translateClick('com1')}>¿Qué es helen-Community? </h2>
                        </div>
                    </div>
                    </div>
                    <div className="row align-items-center " >


                        <div className="col-lg-12">
                            <div className="sc-feature-item">
                                <div className="sc-heading-area sc-mb-20 ">


                                    <div className="description sc-mb-25" >

                                        <p className='parrafo translate' style={{textalign:'justify',textalignlast:'center'}} onClick={()=>translateClick('com2')}>
                                        Aplicación gratuita para dispositivos inteligentes que integra herramientas de 
                                        comunicación para el apoyo a la accesibilidad e inclusión de las personas sordas.
                                        helen-Community permite a los usuarios de la comunidad sorda establecer conversaciones mediante un chat dinámico 
                                        junto con otros productos de la plataforma helen.
                                        </p>
                                        <br></br>
                                        <br></br>

                                    </div>
                                    <section className="sc-service-section-area2  sc-pb-130 sc-md-pb-70">
                                        <div className="container">
                                            <div className=" sc-mb-65 text-center">
                                                <div className="row">
                                                    <Fade bottom delay={150}>
                                                        <div className="col-lg-2 col-md-6">
                                                            <div className="service-content-box first-child text-center sc-sm-mb-30">
                                                                <div className="service_icon">
                                                                    <svg className="pulse" width="50" height="50" viewBox="0 0 100 100" fill="#5f4b8b" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M25.352,26.045c0.347-0.208,0.731-0.37,1.031-0.632c3.378-2.916,7.1-5.305,11.381-6.591
		c2.212-0.665,4.583-0.822,6.897-1.089c0.424-0.047,1.128,0.387,1.316,0.779c0.189,0.398,0.079,1.195-0.222,1.517
		c-0.538,0.577-1.262,1.097-2.006,1.345c-2.846,0.951-5.75,1.731-8.589,2.701c-0.673,0.229-1.164,0.968-1.761,1.445
		c-1.382,1.101-2.778,2.193-4.166,3.287c-0.219,0.174-0.425,0.366-0.637,0.551c0.042,0.076,0.085,0.153,0.127,0.23
		c0.71-0.299,1.404-0.641,2.133-0.883c2.274-0.773,4.514-1.815,6.856-2.161c2.021-0.3,4.167,0.168,6.248,0.39
		c0.484,0.055,0.97,0.439,1.381,0.766c1.52,1.196,3.004,2.436,4.511,3.656c0.518,0.42,0.523,0.824,0.133,1.371
		c-0.926,1.293-2.098,1.699-3.469,0.9c-1.089-0.635-2.065-1.469-3.058-2.254c-0.528-0.418-0.999-0.57-1.722-0.454
		c-1.379,0.216-2.796,0.175-4.191,0.308c-0.513,0.048-1.091,0.166-1.499,0.451c-1.937,1.373-3.842,2.791-5.715,4.25
		c-0.352,0.273-0.506,0.807-0.594,1.381c0.188-0.084,0.394-0.143,0.561-0.256c1.53-1.043,3.307-1.186,5.062-1.407
		c0.559-0.071,1.13-0.071,1.685-0.165c2.269-0.383,4.003,0.707,5.675,2.002c1.375,1.064,2.769,2.104,4.134,3.179
		c0.803,0.632,1.214,1.458,1.207,2.514c-0.013,1.767,0.097,3.54-0.549,5.243c-0.076,0.199-0.116,0.576-0.008,0.66
		c1.075,0.823,0.371,1.738,0.089,2.547c-0.599,1.737-1.318,3.428-1.995,5.137c-0.647,1.627-1.637,2.716-3.481,3.239
		c-4.077,1.161-8.08,2.583-12.104,3.925c-1.645,0.551-3.251,0.766-4.998,0.344c-3.317-0.797-6.583-0.447-9.708,0.938
		c-1.453,0.646-2.867,1.382-4.349,2.103c-1.701-2.443-2.704-5.113-3.327-7.945c-0.735-3.33-0.78-6.69-0.372-10.061
		c0.035-0.291,0.299-0.699,0.558-0.803c3.312-1.352,4.841-4.121,5.937-7.281c1.302-3.749,1.923-7.824,4.223-11.121
		c2.549-3.656,4.515-7.73,7.852-10.85c1.645-1.539,2.97-3.417,4.431-5.148c0.682-0.807,0.996-0.795,1.582,0.094
		c0.668,1.012,0.507,2.094-0.318,3.133c-1.992,2.516-3.942,5.062-5.896,7.605c-0.205,0.271-0.318,0.61-0.474,0.918
		C25.219,25.915,25.285,25.978,25.352,26.045z M39.713,54.342c0.811,0,1.543,0.219,1.945-0.646c0.79-1.7,1.992-3.079,3.444-4.241
		c0.432-0.348,0.389-0.635,0.144-1.1c-0.704-1.348-1.305-2.75-1.996-4.104c-0.194-0.374-0.504-0.747-0.858-0.975
		c-1.229-0.785-2.48-1.543-3.762-2.234c-0.341-0.186-0.872-0.248-1.231-0.125c-0.973,0.338-1.89,0.834-2.848,1.221
		c-0.865,0.35-1.766,0.602-2.628,0.953c-2.055,0.836-2.553,2.734-3.158,4.598c-0.405,1.242-0.131,2.152,0.758,3.075
		c1.176,1.218,2.26,2.532,3.317,3.858c0.374,0.47,0.714,0.496,1.203,0.334C35.93,54.331,37.847,53.971,39.713,54.342z"/>
                                                                        <path d="M74.49,73.967c-0.655,0.49-1.343,0.944-1.957,1.481c-3.015,2.646-6.487,4.538-10.255,5.753
		c-2.191,0.709-4.569,0.902-6.881,1.139c-1.312,0.136-2.023-1.223-1.2-2.281c0.511-0.652,1.351-1.182,2.15-1.454
		c2.43-0.824,4.931-1.438,7.363-2.261c0.881-0.299,1.69-0.891,2.445-1.459c1.571-1.191,3.085-2.458,4.618-3.695
		c0.225-0.182,0.435-0.381,0.529-0.783c-0.837,0.357-1.653,0.771-2.511,1.064c-2.113,0.729-4.203,1.715-6.382,2.002
		c-2.069,0.274-4.243-0.164-6.362-0.385c-0.479-0.049-0.947-0.461-1.365-0.777c-1.328-1.01-2.632-2.049-3.939-3.086
		c-1.345-1.068-1.142-2.455,0.542-2.986c0.656-0.207,1.585-0.141,2.18,0.184c1.187,0.65,2.215,1.592,3.347,2.354
		c0.317,0.217,0.769,0.348,1.151,0.332c1.466-0.06,2.936-0.146,4.391-0.33c0.665-0.081,1.394-0.277,1.933-0.654
		c1.835-1.279,3.62-2.637,5.375-4.027c0.368-0.291,0.531-0.842,0.692-1.375c-2.368,1.559-5.022,1.568-7.64,1.78
		c-1.722,0.138-3.213-0.276-4.568-1.384c-1.756-1.439-3.613-2.756-5.358-4.207c-0.414-0.345-0.79-1.022-0.781-1.539
		c0.033-1.757,0.225-3.513,0.398-5.264c0.057-0.582,0.367-1.139-0.247-1.656c-0.175-0.146-0.18-0.639-0.096-0.922
		c0.209-0.703,0.522-1.371,0.778-2.059c0.491-1.312,0.975-2.627,1.452-3.949c0.649-1.801,1.736-2.988,3.709-3.551
		c4.043-1.165,8.02-2.555,12.019-3.873c1.676-0.557,3.299-0.788,5.092-0.312c3.496,0.927,6.889,0.274,10.134-1.2
		c1.244-0.568,2.443-1.232,3.732-1.887c1.471,1.793,2.239,3.92,2.917,6.08c1.224,3.896,1.276,7.885,0.905,11.908
		c-0.082,0.898-0.825,1.02-1.374,1.251c-1.989,0.841-3.227,2.423-4.062,4.261c-0.906,2-1.605,4.108-2.261,6.207
		c-0.919,2.944-1.89,5.848-3.626,8.441c-1.318,1.967-2.508,4.027-3.907,5.933c-1.142,1.556-2.514,2.942-3.776,4.409
		c-1.347,1.564-2.683,3.138-4.029,4.703c-0.716,0.83-1.061,0.798-1.65-0.145c-0.64-1.025-0.456-2.031,0.413-3.117
		c1.979-2.482,3.914-4.997,5.849-7.514c0.2-0.259,0.275-0.615,0.409-0.926C74.685,74.116,74.587,74.044,74.49,73.967z M54.765,51.12
		c0.635,1.527,1.283,3.151,1.998,4.74c0.157,0.352,0.513,0.662,0.851,0.877c1.237,0.773,2.529,1.465,3.75,2.264
		c0.445,0.292,0.741,0.361,1.247,0.142c1.493-0.655,3.02-1.235,4.544-1.819c1.476-0.56,2.797-1.305,3.38-2.89
		c0.299-0.813,0.575-1.642,0.788-2.479c0.233-0.914,0.105-1.72-0.615-2.483c-1.28-1.351-2.428-2.819-3.668-4.209
		c-0.159-0.182-0.572-0.297-0.799-0.225c-2.13,0.676-4.274,0.991-6.516,0.646c-0.408-0.066-1.043,0.256-1.321,0.604
		C57.123,47.884,55.934,49.55,54.765,51.12z"/>
                                                                    </svg>
                                                                </div>
                                                                <h4 className="service_title translate" onClick={()=>translateClick('com3')}>Avatar intérprete</h4>
                                                            </div>
                                                        </div>
                                                    </Fade>
                                                    <Fade bottom delay={450}>
                                                        <div className="col-lg-2 col-md-6">
                                                            <div className="service-content-box text-center">
                                                                <div className="service_icon">
                                                                    <svg className="pulse" fill="none" width="35" height="35" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M10 15H4V6C4 4.89543 4.89543 4 6 4H18C19.1046 4 20 4.89543 20 6V8" stroke="#5f4b8b" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2 18H14" stroke="#5f4b8b" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14 9.2C14 8.53726 14.597 8 15.3333 8H20.6667C21.403 8 22 8.53726 22 9.2V18.8C22 19.4627 21.403 20 20.6667 20H15.3333C14.597 20 14 19.4627 14 18.8V9.2Z" stroke="#5f4b8b" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18 17H18.01" stroke="#5f4b8b" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    </svg>
                                                                </div>
                                                                <h4 className="service_title translate" onClick={()=>translateClick('com4')}>Disponible para dispositivos Android y iOS</h4>
                                                            </div>
                                                        </div>
                                                    </Fade>
                                                    <Fade bottom delay={350}>
                                                        <div className="col-lg-2 col-md-6">
                                                            <div className="service-content-box text-center sc-sm-mb-30">
                                                                <div className="service_icon">
                                                                    <svg className="pulse" width="28" height="28" viewBox="0 0 57 57" fill="#5f4b8b" xmlns="http://www.w3.org/2000/svg">
                                                                    <g>
	<path d="M22.66,0H3.34C1.498,0,0,1.498,0,3.34v19.32C0,24.502,1.498,26,3.34,26h19.32c1.842,0,3.34-1.498,3.34-3.34V3.34
		C26,1.498,24.502,0,22.66,0z"/>
	<path d="M33.34,26h19.32c1.842,0,3.34-1.498,3.34-3.34V3.34C56,1.498,54.502,0,52.66,0H33.34C31.498,0,30,1.498,30,3.34v19.32
		C30,24.502,31.498,26,33.34,26z"/>
	<path d="M22.66,30H3.34C1.498,30,0,31.498,0,33.34v19.32C0,54.502,1.498,56,3.34,56h19.32c1.842,0,3.34-1.498,3.34-3.34V33.34
		C26,31.498,24.502,30,22.66,30z"/>
	<path d="M55,41H45V31c0-1.104-0.896-2-2-2s-2,0.896-2,2v10H31c-1.104,0-2,0.896-2,2s0.896,2,2,2h10v10c0,1.104,0.896,2,2,2
		s2-0.896,2-2V45h10c1.104,0,2-0.896,2-2S56.104,41,55,41z"/>
</g>
                                                                    </svg>
                                                                </div>
                                                                <h4 className="service_title translate" onClick={()=>translateClick('com5')}>Compatible con plataforma helen </h4>
                                                            </div>
                                                        </div>
                                                    </Fade>
                                                    <Fade bottom delay={250}>
                                                        <div className="col-lg-2 col-md-6">
                                                            <div className="service-content-box text-center sc-sm-mb-30">
                                                                <div className="service_icon">
                                                                    <svg className="pulse" width="30" height="30" viewBox="0 0 30 26" fill="#5f4b8b" xmlns="http://www.w3.org/2000/svg">
                                                                    <g id="message">
	<path d="M28.738,25.208L28.738,25.208c-1.73-0.311-3.77-1.471-4.743-3.621C27.635,19.396,30,15.923,30,12c0-6.627-6.716-12-15-12
		S0,5.373,0,12s6.716,12,15,12c1.111,0,2.191-0.104,3.232-0.287c2.86,1.975,6.252,2.609,10.41,2.139
		c0.248-0.02,0.356-0.148,0.356-0.326C29,25.367,28.887,25.238,28.738,25.208z M9,14c-1.104,0-2-0.895-2-2c0-1.104,0.896-2,2-2
		s2,0.896,2,2C11,13.105,10.104,14,9,14z M15,14c-1.104,0-2-0.895-2-2c0-1.104,0.896-2,2-2s2,0.896,2,2C17,13.105,16.104,14,15,14z
		 M21,14c-1.104,0-2-0.895-2-2c0-1.104,0.896-2,2-2s2,0.896,2,2C23,13.105,22.104,14,21,14z"/>
</g>
<g id="Layer_1_1_">
</g>
                                                                    </svg>
                                                                </div>
                                                                <h4 className="service_title translate" onClick={()=>translateClick('com6')}>Traducción a lengua de señas</h4>
                                                            </div>
                                                        </div>
                                                    </Fade>
  
                                                    <Fade bottom delay={450}>
                                                        <div className="col-lg-2 col-md-6">
                                                            <div className="service-content-box text-center">
                                                                <div className="service_icon">
                                                                    <svg className="pulse" width="35" height="35" viewBox="0 0 32 32" fill="#5f4b8b" xmlns="http://www.w3.org/2000/svg">
                                                                    <polygon fill="none" stroke="#5f4b8b" stroke-width="2" stroke-miterlimit="10" points="25,28 7,28 7,4 19,4 25,10 "/>
<polyline fill="none" stroke="#5f4b8b" stroke-width="2" stroke-miterlimit="10" points="19,4 19,10 25,10 "/>
<rect x="11" y="21" width="10" height="2"/>
<rect x="11" y="17" width="10" height="2"/>
<rect x="11" y="13" width="10" height="2"/>
                                                                    </svg>
                                                                </div>
                                                                <h4 className="service_title translate" onClick={()=>translateClick('com7')}>Transcripción de voz a texto</h4>
                                                            </div>
                                                        </div>
                                                    </Fade>
                                                    
                                                
                                                    <Fade bottom delay={450}>
                                                        <div className="col-lg-2 col-md-6">
                                                            <div className="service-content-box text-center">
                                                                <div className="service_icon">
                                                                    <svg className="pulse" fill="#5f4b8b" width="35" height="35" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                                                                    <path fill="#5f4b8b" d="M28,7.1v2c7.3,1,13,7.3,13,14.9s-5.7,13.9-13,14.9v2c8.4-1,15-8.2,15-16.9S36.4,8.1,28,7.1z"/>
    <path fill="#5f4b8b" d="M14,32H7c-1.1,0-2-0.9-2-2V18c0-1.1,0.9-2,2-2h7V32z"/>
    <polygon fill="#5f4b8b" points="26,42 14,32 14,16 26,6"/>
    <path fill="#5f4b8b" d="M28,17.3v2.1c1.8,0.8,3,2.5,3,4.6s-1.2,3.8-3,4.6v2.1c2.9-0.9,5-3.5,5-6.7S30.9,18.2,28,17.3z"/>
    <path fill="#5f4b8b" d="M28,12.2v2c4.6,0.9,8,5,8,9.8s-3.4,8.9-8,9.8v2c5.7-1,10-5.9,10-11.8S33.7,13.1,28,12.2z"/>
                                                                    </svg>
                                                                </div>
                                                                <h4 className="service_title translate" onClick={()=>translateClick('com8')}>Sintetizador de texto a voz</h4>
                                                            </div>
                                                        </div>
                                                    </Fade>

                                                </div>
                                            </div>

                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>

                    </div>

                    
                    <div className="sc-heading-area text-center sc-mb-60 ">


                        <h1 className="heading-title translate" style={{color:'#5f4b8b'}} onClick={()=>translateClick('com9')}>Comunicación al alcance y estilo de todos en cualquier lugar.</h1>
                        <br></br>
                        <br></br>

                        <h2 className="heading-title translate" onClick={()=>translateClick('com10')}>¿Cómo funciona? </h2>
                        <br></br>
                        <p className="translate" onClick={()=>translateClick('com11')}>helen-Community permite que desde cualquier dispositivo inteligente, la comunicación de personas sordas y oyentes.
                            La aplicación, cuenta con una interfaz intuitiva para los usuarios y facilidades de comunicación 
                            como un menú de accesos rápidos, ingreso de mensajes por medio de voz  
                            y sintetizador de texto o voz.
                        </p>
                    </div>

                    <div className="description sc-mb-25 text-center">
                                        <img className="banner-1 " src={translate} width="80%" height="80%" fill="none" />
                                    </div>
                                    <br></br><br></br>


                    <section className="sc-service-section-area2  sc-pb-130 sc-md-pb-70">
                                        <div className="container">
                                            <div className=" sc-mb-65 text-center">
                                                <div className="row text-center">
                                                    <Fade bottom delay={250}>
                                                        <div className="col-lg-4 col-md-6">
                                                            <div className="service-content-box first-child text-center sc-sm-mb-30">
                                                                <div className="service_icon">
                                                                    <svg className="pulse" width="40" height="40" viewBox="0 0 418.684 418.684" fill="#5f4b8b" xmlns="http://www.w3.org/2000/svg">
                                                                    <g>
	<g>
		<g>
			<path d="M387.5,79.342H31.184C13.989,79.342,0,93.331,0,110.525v54.633c0,17.195,13.989,31.184,31.184,31.184H387.5
				c17.195,0,31.184-13.989,31.184-31.184v-54.633C418.684,93.331,404.695,79.342,387.5,79.342z M101.684,181.341h-70.5
				C22.26,181.342,15,174.082,15,165.158v-54.633c0-8.924,7.26-16.184,16.184-16.184h70.5V181.341z M403.684,165.158
				c0,8.924-7.26,16.184-16.184,16.184H116.684v-87H387.5c8.924,0,16.184,7.26,16.184,16.184V165.158z"/>
			<path d="M43.184,169.342c4.142,0,7.5-3.358,7.5-7.5c0-4.687,3.813-8.5,8.5-8.5c4.687,0,8.5,3.813,8.5,8.5
				c0,4.142,3.358,7.5,7.5,7.5c4.142,0,7.5-3.358,7.5-7.5c0-6.176-2.4-11.797-6.309-15.996c3.911-4.199,6.309-9.826,6.309-16.004
				c0-12.958-10.542-23.5-23.5-23.5s-23.5,10.542-23.5,23.5c0,6.179,2.409,11.796,6.322,15.995
				c-3.915,4.199-6.322,9.823-6.322,16.005C35.684,165.984,39.042,169.342,43.184,169.342z M59.184,121.342
				c4.687,0,8.5,3.813,8.5,8.5c0,4.687-3.813,8.5-8.5,8.5c-4.687,0-8.5-3.813-8.5-8.5C50.684,125.155,54.497,121.342,59.184,121.342
				z"/>
			<path d="M291.184,114.342c-12.958,0-23.5,10.542-23.5,23.5s10.542,23.5,23.5,23.5s23.5-10.542,23.5-23.5
				S304.142,114.342,291.184,114.342z M291.184,146.342c-4.687,0-8.5-3.813-8.5-8.5s3.813-8.5,8.5-8.5s8.5,3.813,8.5,8.5
				S295.871,146.342,291.184,146.342z"/>
			<path d="M163.184,114.342c-12.958,0-23.5,10.542-23.5,23.5s10.542,23.5,23.5,23.5s23.5-10.542,23.5-23.5
				S176.142,114.342,163.184,114.342z M163.184,146.342c-4.687,0-8.5-3.813-8.5-8.5s3.813-8.5,8.5-8.5s8.5,3.813,8.5,8.5
				S167.871,146.342,163.184,146.342z"/>
			<path d="M355.184,114.342c-12.958,0-23.5,10.542-23.5,23.5s10.542,23.5,23.5,23.5s23.5-10.542,23.5-23.5
				S368.142,114.342,355.184,114.342z M355.184,146.342c-4.687,0-8.5-3.813-8.5-8.5s3.813-8.5,8.5-8.5s8.5,3.813,8.5,8.5
				S359.871,146.342,355.184,146.342z"/>
			<path d="M227.184,114.342c-12.958,0-23.5,10.542-23.5,23.5s10.542,23.5,23.5,23.5s23.5-10.542,23.5-23.5
				S240.142,114.342,227.184,114.342z M227.184,146.342c-4.687,0-8.5-3.813-8.5-8.5s3.813-8.5,8.5-8.5s8.5,3.813,8.5,8.5
				S231.871,146.342,227.184,146.342z"/>
			<path d="M387.5,222.342H31.184C13.989,222.342,0,236.331,0,253.525v54.633c0,17.195,13.989,31.184,31.184,31.184H387.5
				c17.195,0,31.184-13.989,31.184-31.184v-54.633C418.684,236.331,404.695,222.342,387.5,222.342z M101.684,324.341h-70.5
				C22.26,324.342,15,317.082,15,308.158v-54.633c0-8.924,7.26-16.184,16.184-16.184h70.5V324.341z M403.684,308.158
				c0,8.924-7.26,16.184-16.184,16.184H116.684v-87H387.5c8.924,0,16.184,7.26,16.184,16.184V308.158z"/>
			<path d="M163.183,257.342c-12.958,0-23.5,10.542-23.5,23.5s10.542,23.5,23.5,23.5c12.958,0,23.5-10.542,23.5-23.5
				S176.141,257.342,163.183,257.342z M163.183,289.342c-4.687,0-8.5-3.813-8.5-8.5s3.813-8.5,8.5-8.5c4.687,0,8.5,3.813,8.5,8.5
				S167.87,289.342,163.183,289.342z"/>
			<path d="M227.183,257.342c-12.958,0-23.5,10.542-23.5,23.5s10.542,23.5,23.5,23.5c12.958,0,23.5-10.542,23.5-23.5
				S240.141,257.342,227.183,257.342z M227.183,289.342c-4.687,0-8.5-3.813-8.5-8.5s3.813-8.5,8.5-8.5c4.687,0,8.5,3.813,8.5,8.5
				S231.87,289.342,227.183,289.342z"/>
			<path d="M291.183,257.342c-12.958,0-23.5,10.542-23.5,23.5s10.542,23.5,23.5,23.5s23.5-10.542,23.5-23.5
				S304.141,257.342,291.183,257.342z M291.183,289.342c-4.687,0-8.5-3.813-8.5-8.5s3.813-8.5,8.5-8.5s8.5,3.813,8.5,8.5
				S295.87,289.342,291.183,289.342z"/>
			<path d="M45.184,318.658h32c4.142,0,7.5-3.358,7.5-7.5v-26.633c0-4.142-3.358-7.5-7.5-7.5h-24.5v-10.5c0-4.687,3.813-8.5,8.5-8.5
				s8.5,3.813,8.5,8.5c0,4.142,3.358,7.5,7.5,7.5c4.142,0,7.5-3.358,7.5-7.5c0-12.958-10.542-23.5-23.5-23.5s-23.5,10.542-23.5,23.5
				v44.633C37.684,315.3,41.042,318.658,45.184,318.658z M52.684,292.025h17v11.633h-17V292.025z"/>
		</g>
	</g>
</g>
                                                                    </svg>
                                                                </div>
                                                                <h4 className="service_title translate" onClick={()=>translateClick('com12')}>Paso No.1<br></br> Regístrese ingresando a la aplicación usando un correo electrónico de su preferencia.</h4>
                                                            </div>
                                                        </div>
                                                    </Fade>
                                                    <Fade bottom delay={350}>
                                                        <div className="col-lg-4 col-md-6">
                                                            <div className="service-content-box text-center">
                                                                <div className="service_icon">
                                                                    <svg className="pulse" fill="#5f4b8b" width="32" height="32" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M18.979,4.661c-2.212,0.573-4.284,1.494-6.129,2.735L9.857,4.402l-5.656,5.657l3.042,3.042  c-1.163,1.784-2.036,3.766-2.583,5.883H0v10.031h4.66c0.56,2.165,1.458,4.193,2.66,6.009l-3.118,3.118l5.656,5.656l3.119-3.118  c1.819,1.205,3.853,2.104,6.023,2.664V48h4.062v-8.047C14.665,39.465,8,32.52,8,24c0-8.521,6.665-15.465,15.062-15.953V0h-4.083  V4.661z" fill="#5f4b8b"/><path d="M15,24c0,4.654,3.532,8.482,8.062,8.951v-4.046C20.75,28.466,19,26.44,19,24c0-2.44,1.75-4.466,4.062-4.905  v-4.046C18.532,15.518,15,19.346,15,24z" fill="#5f4b8b"/><polygon fill="#5f4b8b" points="36.957,2.026 36.957,0 26,0 26,8 36.957,8 36.957,6 43.936,6 43.936,40 26,40 26,42 26,43.334   26,48 47.936,48 47.936,2.026 "/><polygon fill="#5f4b8b" points="40.427,18.644 35.845,23.225 35.854,23.231 29.607,29.478 26,25.869 26,29.351 28.653,32.003   28.646,32.011 29.59,32.951 29.597,32.946 29.603,32.951 30.023,32.533 30.026,32.535 37.593,24.971 42.172,20.39 "/>
                                                                    </svg>
                                                                </div>
                                                                <h4 className="service_title translate" onClick={()=>translateClick('com13')}>Paso No.2<br></br>Acepte los términos y condiciones de uso de la aplicación.</h4>
                                                            </div>
                                                        </div>
                                                    </Fade>
                                                    <Fade bottom delay={450}>
                                                        <div className="col-lg-4 col-md-6">
                                                            <div className="service-content-box text-center sc-sm-mb-30">
                                                                <div className="service_icon">
                                                                    <svg className="pulse" width="35" height="35" viewBox="0 0 32 32" fill="#5f4b8b" xmlns="http://www.w3.org/2000/svg">
                                                                    <path fill-rule="evenodd" d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16zm.28-8.675c1.028.711 2.332 1.134 3.744 1.134.351 0 .698-.026 1.039-.077.117.048.23.107.369.187.3.176.701.446 1.2.81.409.299.988.01.988-.493v-1.461c.21-.136.408-.283.595-.442C25.345 22.025 26 20.715 26 19.31c0-.925-.28-1.79-.772-2.537a7.929 7.929 0 01-.627 1.53c.104.323.159.66.159 1.007 0 1.034-.488 2.01-1.352 2.742a4.679 4.679 0 01-.717.499.612.612 0 00-.311.531v.624c-.593-.38-1-.559-1.31-.559a.627.627 0 00-.104.009 5.696 5.696 0 01-2.602-.17 11.45 11.45 0 01-2.083.34zm-7.466-2.922a9.27 9.27 0 001.044.765v2.492c0 .63.725.99 1.236.616 1.41-1.03 2.39-1.612 2.635-1.67.566.09 1.144.135 1.728.135 5.2 0 9.458-3.607 9.458-8.12 0-4.514-4.259-8.121-9.458-8.121S6 10.107 6 14.62c0 2.21 1.03 4.271 2.814 5.783zm4.949.666c-.503 0-1.238.355-2.354 1.104v-1.437a.765.765 0 00-.39-.664 7.815 7.815 0 01-1.196-.833C8.37 18.01 7.55 16.366 7.55 14.62c0-3.61 3.516-6.588 7.907-6.588 4.392 0 7.907 2.978 7.907 6.588s-3.515 6.589-7.907 6.589c-.53 0-1.053-.044-1.564-.13a.784.784 0 00-.13-.01zm-2.337-4.916c.685 0 1.24-.55 1.24-1.226 0-.677-.555-1.226-1.24-1.226-.685 0-1.24.549-1.24 1.226 0 .677.555 1.226 1.24 1.226zm4.031 0c.685 0 1.24-.55 1.24-1.226 0-.677-.555-1.226-1.24-1.226-.685 0-1.24.549-1.24 1.226 0 .677.555 1.226 1.24 1.226zm4.031 0c.685 0 1.24-.55 1.24-1.226 0-.677-.555-1.226-1.24-1.226-.685 0-1.24.549-1.24 1.226 0 .677.555 1.226 1.24 1.226z"/>
                                                                    </svg>
                                                                </div>
                                                                <h4 className="service_title translate" onClick={()=>translateClick('com14')}>Paso No.3<br></br>¡Listo!Traduce a lengua de señas.</h4>
                                                            </div>
                                                        </div>
                                                    </Fade>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    

                    <div className="sc-heading-area text-center sc-mb-60">


                        <h2 className="heading-title translate" onClick={()=>translateClick('com15')}>¿Cuáles son sus beneficios? </h2>
                    </div>
                <div className="container sc-heading-area sc-mb-60 ">
                    <div className="row">
                        <Fade bottom delay={100}>
                       
                            <div className="col-lg-6">
                                <div className="sc-feature-item sc-pr-90 sc-feature-section-area">

                                <div className="feature-content-box">
                                        <div className="feature-icon">
                                            <svg width="28" height="28" viewBox="0 0 16 16" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M3 14h4v.91l.09.09H2.5l-.5-.5v-13l.5-.5h7.72l.35.14 3.28 3.3.15.36v2.54a3.1 3.1 0 0 0-1-.94V6H9.5L9 5.5V2H3v12zm10-9l-3-3v3h3zm.5 4v1h1l.5.5v4l-.5.5h-6l-.5-.5v-4l.5-.5h1V9a2 2 0 0 1 4 0zm-2.707-.707A1 1 0 0 0 10.5 9v1h2V9a1 1 0 0 0-1.707-.707zM9 11v3h5v-3H9z"/>
                                            </svg>
                                        </div>
                                        <div className="sc-feature-text translate" onClick={()=>translateClick('com16')}>
                                            <h4 className="feature-title">Confidencialidad</h4>
                                            <div className="description sc-mb-25">
                                            Protegemos los datos e información compartida en las traducciones.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="feature-content-box">
                                        <div className="feature-icon">
                                            <svg width="35" height="35" viewBox="0 0 24 24" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14,18 L4.5,18 C3.11928813,18 2,16.8807119 2,15.5 C2,15.2238576 2.22385763,15 2.5,15 L4,15 L4,6.48864223 C4,5.10793035 5.11928813,3.98864223 6.5,3.98864223 L17.5087137,3.98864223 C18.8894256,3.98864223 20.0087137,5.10793035 20.0087137,6.48864223 L20.0087137,8 L20.5,8 C21.3284271,8 22,8.67157288 22,9.5 L22,19.5104121 C22,20.3388393 21.3284271,21.0104121 20.5,21.0104121 L15.5,21.0104121 C14.6715729,21.0104121 14,20.3388393 14,19.5104121 L14,18 L14,18 Z M14,17 L14,16 L3.08535285,16 C3.29127106,16.5825962 3.84689059,17 4.5,17 L14,17 L14,17 Z M14,15 L14,14 L6.5,14 C6.22385763,14 6,13.7761424 6,13.5 L6,6.5 C6,6.22385763 6.22385763,6 6.5,6 L17.5,6 C17.7761424,6 18,6.22385763 18,6.5 L18,8 L19.0087137,8 L19.0087137,6.48864223 C19.0087137,5.6602151 18.3371408,4.98864223 17.5087137,4.98864223 L6.5,4.98864223 C5.67157288,4.98864223 5,5.6602151 5,6.48864223 L5,15 L14,15 Z M14,13 L14,9.5 C14,8.67157288 14.6715729,8 15.5,8 L17,8 L17,7 L7,7 L7,13 L14,13 Z M15.5,9 C15.2238576,9 15,9.22385763 15,9.5 L15,19.5104121 C15,19.7865545 15.2238576,20.0104121 15.5,20.0104121 L20.5,20.0104121 C20.7761424,20.0104121 21,19.7865545 21,19.5104121 L21,9.5 C21,9.22385763 20.7761424,9 20.5,9 L15.5,9 Z M17.5,19 C17.2238576,19 17,18.7761424 17,18.5 C17,18.2238576 17.2238576,18 17.5,18 L18.5,18 C18.7761424,18 19,18.2238576 19,18.5 C19,18.7761424 18.7761424,19 18.5,19 L17.5,19 Z"/>
                                            </svg>
                                        </div>
                                        <div className="sc-feature-text translate" onClick={()=>translateClick('com17')}>
                                            <h4 className="feature-title">Compatibilidad</h4>
                                            <div className="description sc-mb-25">
                                                Compatible con cualquier tipo de dispositivos inteligentes.
                                               </div>
                                        </div>
                                    </div>

                                    <div className="feature-content-box">
                                        <div className="feature-icon">
                                            <svg width="28" height="24" viewBox="0 0 32 32" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                                            <path className="cls-1" d="M22.82,20.55l-.63-.18c-1.06-.29-1.79-.51-1.91-1.75,2.83-3,2.79-5.67,2.73-8.47,0-.38,0-.76,0-1.15a7.1,7.1,0,0,0-7-7A7.1,7.1,0,0,0,9,9c0,.39,0,.77,0,1.15-.06,2.8-.1,5.45,2.73,8.47-.12,1.24-.85,1.46-1.91,1.75l-.63.18C5.61,21.74,2,25,2,29a1,1,0,0,0,2,0c0-3,3-5.61,5.82-6.55.16-.06.34-.1.52-.15A4.11,4.11,0,0,0,13.45,20a5.4,5.4,0,0,0,5.1,0,4.11,4.11,0,0,0,3.11,2.35c.18.05.36.09.52.15C25,23.39,28,26,28,29a1,1,0,0,0,2,0C30,25,26.39,21.74,22.82,20.55Zm-9.36-3C10.9,15,10.94,12.86,11,10.18,11,9.8,11,9.4,11,9A5,5,0,0,1,21,9c0,.4,0,.8,0,1.18,0,2.68.09,4.8-2.47,7.36A3.58,3.58,0,0,1,13.46,17.54Z"/>
                                            </svg>
                                        </div>
                                        <div className="sc-feature-text translate" onClick={()=>translateClick('com18')}>
                                            <h4 className="feature-title">Autonomía</h4>
                                            <div className="description sc-mb-25">
                                                Uso del servicio sin necesidad de interacción con terceros.
                                            </div>
                                        </div>
                                    </div>
                                  
                                </div>
                            </div>
                           
                        </Fade>

                        <Fade bottom delay={200}>
                        <div className="row col-lg-6">
                                <div className="sc-feature-item sc-pr-90 sc-feature-section-area ">

                                    <div className="feature-content-box">
                                        <div className="feature-icon">
                                            <svg width="35" height="35" viewBox="0 0 256 256" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M63.1,106.3H9.9c-2.7,0-4.9,2.2-4.9,4.9c0,2.7,2.2,4.9,4.9,4.9h53.2c2.7,0,4.9-2.2,4.9-4.9C68,108.5,65.8,106.3,63.1,106.3   z"/>

<path d="M63.1,135.2H38.6c-2.7,0-4.9,2.2-4.9,4.9c0,2.7,2.2,4.9,4.9,4.9h24.5c2.7,0,4.9-2.2,4.9-4.9   C68,137.4,65.8,135.2,63.1,135.2z"/>

<path d="M63.1,164.1H22.7c-2.7,0-4.9,2.2-4.9,4.9c0,2.7,2.2,4.9,4.9,4.9h40.4c2.7,0,4.9-2.2,4.9-4.9   C68,166.3,65.8,164.1,63.1,164.1z"/>

<path d="M242.9,103.9c-4-8.5-9.3-16.2-15.8-22.9c-3.1-3.3-6.6-6.3-10.2-9l6.4-8.5c1.6-2.2,1.2-5.3-1-6.9c-2.2-1.6-5.3-1.2-6.9,1   l-6.8,9c-4.5-2.7-9.3-4.9-14.2-6.7c-4.3-1.5-8.7-2.7-13.2-3.6c-2.9-0.5-5.9-0.9-8.8-1.1V40.3h9.9c2.7,0,4.9-2.2,4.9-4.9   c0-2.7-2.2-4.9-4.9-4.9h-29.7c-2.7,0-4.9,2.2-4.9,4.9c0,2.7,2.2,4.9,4.9,4.9h9.9v14.5c-11.4,0.4-22.7,3.1-33.1,7.9   c-8.5,4-16.2,9.2-23,15.7c-9.5,9-16.8,20.3-21.3,32.6c-1.6,4.3-2.8,8.9-3.7,13.5c-0.9,4.6-1.3,9.2-1.5,13.8   c-0.3,13.2,2.5,26.4,8.2,38.4c4,8.4,9.2,16,15.7,22.7c9.1,9.5,20.4,16.8,32.7,21.2c4.3,1.5,8.7,2.7,13.2,3.6   c4.5,0.8,9,1.3,13.6,1.4c0.7,0,1.5,0,2.2,0c12.4,0,24.8-2.7,36-8c8.4-3.9,16.1-9.1,22.8-15.5c9.6-9.1,17-20.4,21.5-32.8   c1.6-4.3,2.8-8.9,3.6-13.4c0.9-4.6,1.4-9.3,1.5-13.9C251.3,128.9,248.5,115.7,242.9,103.9z M239.8,154   C239.8,154,239.8,154,239.8,154c-0.8,4.1-1.8,8-3.2,11.9c-4,10.9-10.5,21-19,29c-5.9,5.6-12.7,10.3-20.2,13.7   c-10.5,4.9-22.3,7.4-33.7,7c-4-0.1-8-0.5-12-1.3c-4-0.7-7.9-1.8-11.7-3.1c-10.9-3.9-20.9-10.4-28.9-18.8   c-5.7-5.9-10.4-12.7-13.9-20.1c-5-10.5-7.5-22.3-7.2-33.9c0.1-4.1,0.5-8.2,1.3-12.2c0.8-4.1,1.9-8.1,3.2-11.9   c3.9-10.9,10.4-20.8,18.8-28.8c6-5.7,12.8-10.4,20.4-13.9c9.9-4.6,20.9-7,31.8-7c0.6,0,1.3,0,1.9,0c4,0.1,8,0.5,12,1.3   c4,0.7,7.9,1.8,11.7,3.2C202,73,212,79.4,220,87.8c5.7,6,10.4,12.8,14,20.3c4.9,10.5,7.4,22.1,7.1,33.7   C241,145.8,240.6,150,239.8,154z"/>

<path d="M196.1,105.9L175,125c-1.1-0.7-2.2-1.2-3.4-1.7c-0.9-0.3-1.8-0.6-2.7-0.7c-1-0.2-1.9-0.3-2.8-0.3c-2.7-0.1-5.5,0.5-8,1.7   c-1.8,0.8-3.4,1.9-4.8,3.3c-2,1.9-3.5,4.2-4.4,6.8c-0.3,0.9-0.6,1.8-0.8,2.8c-0.2,1-0.3,1.9-0.3,2.9c-0.1,2.8,0.5,5.5,1.7,8   c0.8,1.8,1.9,3.3,3.3,4.7c1.9,2,4.3,3.5,6.8,4.4c0.9,0.3,1.8,0.6,2.7,0.7c0.9,0.2,1.9,0.3,2.8,0.3c0.2,0,0.3,0,0.5,0   c2.6,0,5.2-0.6,7.5-1.7c1.7-0.8,3.4-1.9,4.8-3.2c2-1.9,3.5-4.3,4.5-6.8c0.3-0.9,0.6-1.8,0.8-2.8c0.2-1,0.3-1.9,0.3-2.9   c0.1-2.7-0.5-5.5-1.7-8c0-0.1-0.1-0.1-0.1-0.2l21.2-19.1c2-1.8,2.2-5,0.4-7C201.3,104.3,198.2,104.1,196.1,105.9z M173.4,141.6   C173.4,141.6,173.4,141.6,173.4,141.6c-0.1,0.4-0.2,0.9-0.3,1.3c-0.4,1.1-1.1,2.2-2,3c-0.6,0.6-1.3,1.1-2.1,1.4   c-1.1,0.5-2.3,0.7-3.5,0.7c-0.4,0-0.9-0.1-1.3-0.1c-0.4-0.1-0.8-0.2-1.2-0.3c-1.1-0.4-2.2-1.1-3-2c-0.6-0.6-1.1-1.3-1.5-2.1   c-0.5-1.1-0.8-2.3-0.8-3.5c0-0.4,0.1-0.9,0.1-1.3c0.1-0.4,0.2-0.9,0.3-1.3c0.4-1.1,1.1-2.2,2-3c0.6-0.6,1.3-1.1,2.1-1.5   c1.1-0.5,2.2-0.7,3.3-0.7c0.1,0,0.1,0,0.2,0c0.4,0,0.8,0.1,1.3,0.1c0.4,0.1,0.8,0.2,1.2,0.3c1.2,0.4,2.2,1.1,3,2   c0.6,0.6,1.1,1.4,1.5,2.1c0.5,1.1,0.8,2.3,0.7,3.5C173.5,140.7,173.4,141.2,173.4,141.6z"/>
                                            </svg>
                                        </div>
                                        <div className="sc-feature-text translate" onClick={()=>translateClick('com19')}>
                                            <h4 className="feature-title">Eficiencia</h4>
                                            <div className="description sc-mb-25">
                                                Traducción en segundos, sin límites de texto. 
                                               </div>
                                        </div>
                                    </div>
                                    <div className="feature-content-box">
                                        <div className="feature-icon">
                                            <svg width="20" height="20" viewBox="0 0 57 57" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                                            <g>
	<path d="M22.66,0H3.34C1.498,0,0,1.498,0,3.34v19.32C0,24.502,1.498,26,3.34,26h19.32c1.842,0,3.34-1.498,3.34-3.34V3.34
		C26,1.498,24.502,0,22.66,0z"/>
	<path d="M33.34,26h19.32c1.842,0,3.34-1.498,3.34-3.34V3.34C56,1.498,54.502,0,52.66,0H33.34C31.498,0,30,1.498,30,3.34v19.32
		C30,24.502,31.498,26,33.34,26z"/>
	<path d="M22.66,30H3.34C1.498,30,0,31.498,0,33.34v19.32C0,54.502,1.498,56,3.34,56h19.32c1.842,0,3.34-1.498,3.34-3.34V33.34
		C26,31.498,24.502,30,22.66,30z"/>
	<path d="M55,41H45V31c0-1.104-0.896-2-2-2s-2,0.896-2,2v10H31c-1.104,0-2,0.896-2,2s0.896,2,2,2h10v10c0,1.104,0.896,2,2,2
		s2-0.896,2-2V45h10c1.104,0,2-0.896,2-2S56.104,41,55,41z"/>
</g>
</svg>
                                        </div>
                                        <div className="sc-feature-text translate" onClick={()=>translateClick('com20')}>
                                            <h4 className="feature-title">Connectividad</h4>
                                            <div className="description sc-mb-25">
                                            Compatibilidad con otros productos de la plataforma helen.
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </Fade>
                        
                    </div>
                </div>
                    
                </div>

               
            </section>

            <section className="sc-apps-section-area sc-apps-bottom">
            <br></br> 
            <div className="container">
        <div className="row">
            <div className="bannerLinks">
            <div className="row">
                <div className="col-lg-8 translate">
                <p  className="bannerText">Instale nuestra app <span className='bannerTextHighlight'>helen community</span> para comunicarse en lengua de señas.
                </p>
                <p className='bannerText'>
                <a href="https://apps.apple.com/us/app/helen-community/id1576561202"><img src={appStore} alt="appStore"/></a>
                <a href="https://play.google.com/store/apps/details?id=com.helencommunity.helencommunity"><img src={playStore} alt="PlayStore"/></a>
                <a href="https://appgallery.huawei.com/app/C109167727" style={{paddingLeft:"3px"}}><img src={huaweiStore} alt="HuaweiStore" style={{border: "2px solid #9da0a3d9" ,borderRadius: "8px"}}/></a>
                </p>
                </div>
               
                <div className="col-lg-4">
                    <img src={bannerApps}/>
                </div>
                </div>
            </div>

        </div>
    </div>
    
       
        </section>

        </>
    );
};

export default ServiceFeature;