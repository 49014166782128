import React from 'react';

const Blog2 = () => {
    return (
        <>
            <section>
                <div className="sc-blog-details sc-pt-100 sc-md-pt-40 sc-pb-20 sc-md-pb-20">
                    <div className="container ">
                        <div className="row">
                            <div className='col-lg-2'></div>
                            <div className="col-lg-8">
                                <div className="details-content-area sc-md-mb-50">
                                    <div className="details-content">
                                        <div className="description sc-mb-25">

                                            Bienvenido(a) a helenAI, es nuestro compromiso facilitar la comunicación de personas sordas, mediante productos y servicios basados en inteligencia artificial. Los presentes Términos y Condiciones definen la relación entre usted y el uso del presente sitio web asociado a nuestra marca.                     
                                            <br></br>
                                            <br></br>
                                            Desde formularios hasta pagos, nos esforzamos por proporcionar una experiencia segura y transparente. Su privacidad es nuestra prioridad, y queremos asegurarle que su información personal se trata con el máximo respeto. Al acceder y utilizar nuestros servicios, acepta estos términos, forjando así una colaboración que prioriza su seguridad, privacidad y experiencia con helenAI. Agradecemos su confianza y preferencia en nuestros productos y servicios.
                                        </div>
                                        <div className="description sc-mb-25">
                                            <h3>Recolección de datos </h3>
                                            Dentro de nuestro sitio, contamos con formularios de contacto, registro y de solicitud de soporte, donde podríamos solicitar información tal como correos electrónicos, nombres personales, números de teléfonos, entre otros. Esta información se utiliza únicamente para contactarle y así poder responder sus consultas de la mejor manera posible. Ninguna de esta información es compartida con terceros. Se le insta a no compartir esta información. Nos reservamos y adjudicamos a su persona la responsabilidad de salvaguardar y supervisar, todas las actividades llevadas a cabo en su cuenta. Estas medidas son fundamentales para garantizar la seguridad y la integridad de su cuenta.<br></br>
                                            <br></br><h4>Cookies</h4>
                                            Una cookie es un pequeño archivo de texto que un sitio web almacena en el dispositivo del usuario cuando lo visita. Este archivo contiene información que el sitio web puede recuperar más tarde cuando el usuario vuelve a visitar el sitio.
                                            <br></br>
                                            <br></br>
                                            Nuestro sitio no utiliza cookies para recopilar información personal o realizar un seguimiento de sus actividades en línea. Entendemos la importancia de la privacidad en línea y nos comprometemos a proporcionar una experiencia transparente y segura para nuestros visitantes.
                                            <br></br>
                                            
                                            
                                            
                                        </div>
                                        <div className="description sc-mb-25 style:{{textalign:'justify'}}">
                                            <h3>Contenido externo</h3>
                                            
                                            El contenido de otros sitios web se comporta exactamente de la misma manera que si el visitante hubiera visitado el otro sitio web. El contenido de terceros, tales como videos, artículos entre otros, puede estar incluido en este sitio web.
                                            <br></br>
                                        
                                        </div>
                                        <div className="description sc-mb-25 style:{{textalign:'justify'}}">
                                            <h3>¿Que hacemos con sus datos?</h3>
                                            Retenemos la información recopilada durante el tiempo que sea necesario para brindarle el servicio solicitado a nuestros usuarios. Los datos brindados son debidamente protegidos dentro de medios comercialmente aceptables para evitar pérdidas y robos, así como el acceso, divulgación, copia, uso o modificación no autorizada. No compartimos ninguna información de identificación personal de manera pública o con terceros, excepto cuando así lo requiera la ley. 
                                            <br></br>
                                            <br></br>
                                            Los usuarios registrados en nuestro sitio web,tienen la capacidad de editar o eliminar esta información en cualquier momento. Los administradores del sitio web también pueden ver y editar esa información siempre y cuando el usuario lo solicite y autorice. 
                                        </div>
                                     <div className="description sc-mb-25 style:{{textalign:'justify'}}">
                                            <h3>Pagos y facturación</h3>
                                    
                                            El procesamiento de pagos vinculados al uso de algunos de nuestros productos o  servicios,se realiza mediante terceros. Los terceros encargados del proceso de cobro, podrían retener información relacionada con el pago por servicios,ninguna de esta información personal es almacenada por nosotros . La responsabilidad de garantizar la exactitud de la información de pago durante la transacción recae en el usuario.
                                        </div>
                                        <div className="description sc-mb-25 style:{{textalign:'justify'}}">
                                            <h3>¿Requisitos de divulgación reglamentarios de la industria?</h3>
                                            El Reglamento General de Protección de Datos (GDPR, por sus siglas en inglés) exige una protección especial para los datos personales del Espacio Económico Europeo (EEE). Asimismo, otorga derechos individuales a las personas con sede en la Unión Europea (UE) con respecto a su información personal. Nos comprometemos a realizar todos los esfuerzos razonables para cumplir con el GDPR y proporcionar, al menos, el mismo nivel de protección para los datos personales recibidos y procesados de la UE que las garantías de privacidad establecidas en nuestra política de privacidad. Además, nos esforzamos por atender de manera razonable las solicitudes de los Interesados para ejercer los derechos del GDPR.
                                            <br></br>
                                            <br></br>
                                            Es importante destacar que, considerando nuestra operación en Costa Rica, también nos comprometemos a cumplir con las regulaciones locales de protección de datos y privacidad. Hemos implementado medidas organizativas y técnicas, incluyendo políticas internas de protección de datos, y mantenemos documentación sobre nuestras actividades de procesamiento, garantizando así el cumplimiento tanto de las normativas internacionales como de las leyes locales pertinentes.
                                        </div>
                                        
                                    </div>

                                </div>
                            </div>
                            <div className='col-lg-2'></div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    );
};

export default Blog2;