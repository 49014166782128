import React from 'react';
import { Fade } from 'react-reveal';
import { HashLink as Link } from 'react-router-hash-link';
import whiteLogo from '../../assets/images/logoVerde.png'

const Footer = () => {
    return (
        <>
            <section className="sc-footer-section sc-pt-200 sc-md-pt-170 sc-sm-pt-100">
                <div className="container">
                    <div className="row padding-bottom">

                        <Fade fade delay={100}>
                            <div className="col-lg-3 col-sm-6">
                                <div className="footer-about">
                                    <div className="footer-logo sc-mb-25">
                                        <Link to="#"><img src={whiteLogo} alt="FooterLogo" /></Link>
                                    </div>
                                    <ul className="about-icon">
                                        <li>
                                            <a href="https://www.facebook.com/people/HelenAi/100089439314118/?mibextid=ZbWKwL" target="_blank" rel="noreferrer"><i className="ri-facebook-fill"></i></a>
                                        </li>
                                        <li>
                                            <a href="https://www.linkedin.com/company/helenai" target="_blank" rel="noreferrer"><i className="ri-linkedin-fill"></i></a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/_helen_ai_/?igshid=YmMyMTA2M2Y%3D" target="_blank" rel="noreferrer"><i className="ri-instagram-fill"></i></a>
                                        </li>
                                        <li>
                                            <a href="https://www.youtube.com/@helenAI-ky9cg/featured" target="_blank" rel="noreferrer"><i className="ri-youtube-fill"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Fade>

                        <Fade fade delay={200}>
                            <div className="col-lg-3 col-sm-6 sc-xs-mt-40">
                            </div>
                        </Fade>
                        <Fade fade delay={200}>
                            <div className="col-lg-3 col-sm-6 sc-sm-mt-40">
                                <div className="footer-menu-area footer-menu-area-left sc-pl-65 sc-lg-pl-0 sc-md-pl-0">
                                    <ul className="footer-menu-list">
                                       
                                        <li><i className="ri-whatsapp-fill"></i><a href="https://wa.me/50684834461">+506 84834461</a></li>
                                        <li><i className="ri-whatsapp-fill"></i><a href="https://wa.me/50688705599">+506 88705599</a></li>
                                       
                                    </ul>
                                </div>
                            </div>
                            
                        </Fade>
                        <Fade fade delay={200}>
                            <div className="col-lg-3 col-sm-6 sc-sm-mt-40">
                                <div className="footer-menu-area footer-menu-area-left sc-pl-65 sc-lg-pl-0 sc-md-pl-0">
                                    <ul className="footer-menu-list">
                                       
                                        <li><i className="ri-mail-fill"></i><a href="mailto:hello@helenai.com">hello@helenai.com</a></li>
                                        <li className="footer-map"><i className="ri-map-pin-fill"></i><a href="https://www.google.com/maps/place/Mata+Redonda,+San+Jos%C3%A9/@9.9352781,-84.1209554,15z/data=!3m1!4b1!4m6!3m5!1s0x8fa0fca8bfb73e37:0x81473ea6db7a7c40!8m2!3d9.9334277!4d-84.1103725!16s%2Fm%2F05p4fg4?hl=es&entry=ttu"><span>San José, Costa Rica</span></a></li>
                                    </ul>
                                </div>
                            </div>
                        </Fade>
                    </div>
                </div>

                <div className="copyright-area">
                    <div className="container">
                        <div className="border-top"></div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="copyright-text text-center">
                                    <a style={{fontSize:'70%'}} href="/terms#">Términos y condiciones</a>
                                    <p style={{fontSize:'80%'}}>&copy; {new Date().getFullYear()} TauTechnologies S.A. </p>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Footer;