import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import { Helmet } from 'react-helmet';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../src/assets/css/style.css';
import '../src/assets/fonts/remixicon.css';

// Components
import About from './component/Pages/About/AboutSpa';
import Blog from './component/Pages/Blog/Blog';
import Contact from './component/Pages/Contact/Contact';
import Error404 from './component/Pages/Error404/Error404';
import Faq from './component/Pages/Faq/Faq';
import HomeSpa from './component/Pages/Home/HomeSpa';
import Login from './component/Pages/Login/Login';
import Services from './component/Pages/Services/Services';
import ServicesTrans from './component/Pages/Services/ServicesTranslate';
import ServicesCloud from './component/Pages/Services/ServicesCloud';
import ServicesCom from './component/Pages/Services/ServicesCommunity';
import SignUp from './component/Pages/SignUp/SignUp';
import Team from './component/Pages/Team/Team';
import TeamDetails from './component/Pages/TeamDetails/TeamDetails';
import ScrollUpBtn from './component/Shared/ScrollUpBtn';
import Blog1 from './component/Pages/Blog/Blog1';
import Blog2 from './component/Pages/Blog/Blog2';
import Blog3 from './component/Pages/Blog/Blog3';
import Blog4 from './component/Pages/Blog/Blog4';
import Terms from './component/Pages/Terms/Terms';
import Soporte from './component/Pages/Soporte/Soporte';

import React, { useState } from "react";
import { DndContext,DragOverlay } from "@dnd-kit/core";
import { Draggable } from "./Draggable";
import { Droppable } from "./Droppable";
import {
  restrictToWindowEdges,
} from '@dnd-kit/modifiers';
const notesData = [
  {
    id: "1",
    content: "",
    position: {
      x: 0,
      y: 0
    }
  }
];

export default function App() {
  
  const [notes, setNotes] = useState(notesData);

  function handleDragEnd(ev) {
    const note = notes.find((x) => x.id === ev.active.id);
    note.position.x += ev.delta.x;
    note.position.y += ev.delta.y;
    const _notes = notes.map((x) => {
      if (x.id === note.id) return note;
      return x;
    });
    setNotes(_notes);
  }

  return (
    <div>
      <Helmet>
        <title>helen:Communicación para todos.</title>
        <link rel="shortcut icon" href="favicon.ico" type="image/x-icon" />
      </Helmet>

      <Routes>
        <Route path='/' element={<HomeSpa />}></Route>
        <Route path='/about' element={<About />} ></Route>
        <Route path='/contact' element={<Contact />}></Route>
        <Route path='/soporte' element={<Soporte/>}></Route>
        <Route path='/blog' element={<Blog />}></Route>
        <Route path='/blog-1' element={<Blog1 />}></Route>
        <Route path='/blog-2' element={<Blog2 />}></Route>
        <Route path='/blog-3' element={<Blog3 />}></Route>
        <Route path='/blog-4' element={<Blog4 />}></Route>
        <Route path='/services' element={<Services />}></Route>
        <Route path='/servicetran' element={<ServicesTrans />}></Route>
        <Route path='/servicecloud' element={<ServicesCloud />}></Route>
        <Route path='/servicecom' element={<ServicesCom />}></Route>
        <Route path='/team' element={<Team />}></Route>
        <Route path='/team-details' element={<TeamDetails />}></Route>
        <Route path='/faq' element={<Faq />}></Route>
        <Route path='/login' element={<Login />}></Route>
        <Route path='/register' element={<SignUp />}></Route>
        <Route path='*' element={<Error404 />}></Route>
        <Route path='/terms' element={<Terms />}></Route>
      
      </Routes>
      <ScrollUpBtn />
      <ToastContainer />
      <DndContext modifiers={[restrictToWindowEdges]} onDragEnd={handleDragEnd}>
      <DragOverlay modifiers={[restrictToWindowEdges]}>
      </DragOverlay>
      <Droppable>
        {notes.map((note) => (
          <Draggable
            styles={{
              position: "absolute",
              left: `${note.position.x}px`,
              top: `${note.position.y}px`
            }}
            key={note.id}
            id={note.id}
            content={note.content}
          />
          
        ))}
      </Droppable>
    </DndContext>
    </div>
  );
}


