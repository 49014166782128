import React from 'react';
import { Fade } from 'react-reveal';
import serviceGroup1 from '../../assets/images/service/Translate.png'
import serviceGroup2 from '../../assets/images/service/Cloud.png'
import serviceGroup3 from '../../assets/images/service/Community.png'
import { HashLink as Link } from 'react-router-hash-link';

import home5 from '../../assets/glb/Home/home5.glb'
import home6 from '../../assets/glb/Home/home6.glb'
import home7 from '../../assets/glb/Home/home7.glb'
import home8 from '../../assets/glb/Home/home8.glb'
import home9 from '../../assets/glb/Home/home9.glb'
import home10 from '../../assets/glb/Home/home10.glb'
import home11 from '../../assets/glb/Home/home11.glb'
import home12 from '../../assets/glb/Home/home12.glb'
import home13 from '../../assets/glb/Home/home13.glb'
import home14 from '../../assets/glb/Home/home14.glb'
/*
const home5="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/home5.glb"
const home6="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/home6.glb"
const home7="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/home7.glb"
const home8="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/home8.glb"
const home9="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/home9.glb"
const home10="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/home10.glb"
const home11="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/home11.glb"
const home12="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/home12.glb"
const home13="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/home13.glb"
const home14="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/home14.glb"
*/

const Service = () => {
    const translateClick = (model) => {
        var speed=document.getElementById("btnVelocity").innerText;
        //obtener el componente de modelviewer por medio del id
        var modelViewer = document.querySelector('#helenai');
        switch (model) {
            case 'home5':
                modelViewer.src=home5;
                break;
            case 'home6':
                modelViewer.src=home6;
                break;
            case 'home7':
                modelViewer.src=home7;
                break;
            case 'home8':
                modelViewer.src=home8;
                break;
            case 'home9':
                modelViewer.src=home9;
                break;
            case 'home10':
                modelViewer.src=home10;
                break;
            case 'home11':
                modelViewer.src=home11;
                break;
           case 'home12':
               modelViewer.src=home12;
                break;
            case 'home13':
                modelViewer.src=home13;
                break;
            case 'home14':
                modelViewer.src=home14;
                break;
        }
     modelViewer.timeScale = speed;
     modelViewer.play({repetitions: 1});
      }
    return (
        <>
            <section className="sc-service-section-area sc-pt-210 sc-md-pt-90 sc-pb-140 sc-md-pb-50 sc-pb-30">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 offset-md-3">
                            <div className="sc-heading-area text-center sc-mb-60">
                            <h2 className="sc-mb-0 translate" onClick={()=>translateClick('home5')}><Link to="/services#">Plataforma de productos</Link></h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                    <Fade left delay={200}>
                    <div className="col-lg-4 col-md-4 col-sm-6 ">
                    <div className="sc-service-content-box text-center sc-md-mb-30">
                    <div className="service-icon">
                    <Link to="/servicetran#"><img className="banner-1" src={serviceGroup1} alt="Banner" width="85" height="75" viewBox="0 0 70 48" fill="none"/></Link>
                        <h4 className="sc-mb-0 translate mt-2" onClick={()=>translateClick('home6')}><Link to="/servicetran#">helen Translate</Link></h4>
                        <br></br>
                        <p className="translate" onClick={()=>translateClick('home7')} style={{fontSize:'80%'}}>Atención de personas 
                        <br></br>
                        en plataformas de servicio.</p>
                        <p  onClick={()=>translateClick('home8')} className="translate" style={{fontSize:'65%',fontWeight:'bold'}}>Empresas</p>
                        </div>
                        </div>
                        </div>
                    </Fade>

                    <Fade left delay={300}>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                    <div className="sc-service-content-box text-center sc-md-mb-30">
                    <div className="service-icon">
                    <Link to="/servicecloud"><img className="banner-1" src={serviceGroup2} alt="Banner" width="115" height="75" viewBox="0 0 70 48" fill="none"/></Link>
                        <h4 onClick={()=>translateClick('home9')} className="sc-mb-0 translate mt-3"><Link to="/servicecloud#">helen Cloud</Link></h4>
                  
                        <br></br>
                        <p  onClick={()=>translateClick('home10')} className="translate" style={{fontSize:'80%'}}>Conversión de contenido digital
                        <br></br>
                        en lengua de señas.</p>
                        <p onClick={()=>translateClick('home11')} className="translate" style={{fontSize:'65%',fontWeight:'bold'}}>Empresas</p>
                        </div>
                        </div> 
                        
                        </div>
                    </Fade>
                    <Fade left delay={400}>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                    <div className="sc-service-content-box text-center sc-md-mb-30">
                    <div className="service-icon">
                    <Link to="/servicecom#"><img className="banner-1" src={serviceGroup3} alt="Banner" width="90" height="75" viewBox="0 0 70 48" fill="none"/></Link>
                        <h4 className="sc-mb-0 translate mt-2" onClick={()=>translateClick('home12')}><Link to="/servicecom#">helen Community</Link></h4>
                        <br></br>
                        <p  onClick={()=>translateClick('home13')} className="translate" style={{fontSize:'80%'}}>Asistente en la comunicación de personas en 
                        lengua de señas.</p>
                        <p  onClick={()=>translateClick('home14')} className="translate" style={{fontSize:'65%',fontWeight:'bold'}}>Personas</p>
                        </div>
                        </div>
                        </div>
                    </Fade>
                    

                    </div>
                </div>

            </section>
        </>
    );
};

export default Service;