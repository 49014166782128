import React from 'react';
import { useLocation } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';

const MenuItems = (props) => {
    const { parentMenu } = props;
    const location = useLocation();

    return (
        <>
        
            <li className="menu-item-has-children">
                <Link className={parentMenu === 'pages' ? 'menu-item-has-children' : 'menu-item-has-children'} to="/services#">Productos</Link>
                <ul className="sub-menu-list">
                    <li><Link className={location.pathname === '/services' ? 'active' : ''} to="/servicetran#">helen Translate</Link></li>
                    <li><Link className={location.pathname === '/services' ? 'active' : ''} to="/servicecloud#">helen Cloud</Link></li>
                    <li><Link className={location.pathname === '/services' ? 'active' : ''} to="/servicecom#">helen Community</Link></li>
                </ul>
            </li>
                
            <Link className={parentMenu === 'pages' ? 'menu-item-has-children active' : 'menu-item-has-children'} to="#"></Link>

                    <li><Link className={location.pathname === '/faq' ? 'active' : ''} to="/faq#">Preguntas Frecuentes</Link></li>
                    <li><Link className={location.pathname === '/blog' ? 'active' : ''} to="/blog#">Blog</Link></li>
  
            <li className="menu-item-has-children">
                <Link className={parentMenu === 'pages' ? 'menu-item-has-children' : 'menu-item-has-children'} to="/contact#">Contacto</Link>
                <ul className="sub-menu-list">
                    <li><Link className={location.pathname === '/contact' ? 'active' : ''} to="/contact#">Contacto</Link></li>
                    <li><Link className={location.pathname === '/contact' ? 'active' : ''} to="/soporte#">Soporte</Link></li>

                </ul>
            </li>



{/*
            <li className="menu-item-has-children">
                    <Link className={parentMenu === 'pages' ? 'menu-item-has-children' : 'menu-item-has-children'} to="">Portal helenCloud</Link>
                <ul className="sub-menu-list">
                    <li><Link className={location.pathname === '/login' ? 'active' : ''} to="/login#">Inicio sesión</Link></li>
                    <li><Link className={location.pathname === '/signup' ? 'active' : ''} to="/register#">Registro</Link></li>
                   
                </ul>
            </li>  */}           
        </>
    );
}

export default MenuItems;