import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import pagesBlogImg from '../../../assets/images/blog/art1.png'


const Blog2= () => {
    return (
        <>
            <section>
                <div className="sc-blog-details sc-pt-140 sc-md-pt-80 sc-pb-20 sc-md-pb-20">
                    <div className="container ">
                        <div className="row">
                            <div className='col-lg-2'></div>
                            <div className="col-lg-8">
                                <div className="blog-item">
                                    <div className="blog-image">
                                        <Link smooth to="#"><img src={pagesBlogImg} alt="Blog" /></Link>
                                    </div>
                                    <div className="blog-content p-0">
                                        <div className="blog_date">
                                            <ul>
                                                <li>Marzo</li>
                                                <li>2022</li>
                                            </ul>
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <h3 className="blog-title sc-pt-15 sc-mb-15 fw-bold">
                                            ¿Conoce sobre las Apps para la atención de personas sordas?
                                        </h3>
                                        
                                    </div>
                                </div>
                                <div className="details-content-area sc-md-mb-50">
                                    <div className="details-content">
                                        <div className="description sc-mb-25">
                                        La tecnología y la Inteligencia Artificial se han convertido en aliadas de las empresas y organizaciones que quieren ser más inclusivas y ofrecer un servicio integral a todas las personas.
                                        Además, si lo vemos desde el punto de vista del negocio, a las marcas les conviene brindar una atención al cliente que sea de calidad para todos y todas.
                                        </div>
                                        <div className="description sc-mb-25">
                                        <h4>Una ventaja competitiva:</h4>
                                        Si consideramos que, según la Organización Mundial de la Salud, en el mundo, 1500 millones de personas viven con algún grado de pérdida de audición, de las cuales unos 430 millones necesitan servicios de rehabilitación debido a su grado de sordera, definitivamente incorporar mecanismos que le permitan a los representantes de la empresa (agentes de venta, altos ejecutivos y asistentes técnicos) comunicarse con esa población es una inversión inteligente que generará ingresos económicos y reputación.
                                        </div>
                                        <div className="description sc-mb-25">
                                        <h4>El caso de Costa Rica:</h4>
                                        Más de 70 mil personas sordas viven en Costa Rica según datos del Instituto Nacional de Estadística y Censos (INEC).
                                        Esas personas, al igual que los demás, realizan actividades económicas como comprar productos, contratar servicios, trabajar y estudiar. Pero históricamente han enfrentado trabas y opciones limitadas para acceder a trámites gubernamentales y bancarios, gestiones de salud, entretenimiento y transporte debido a la ausencia de universalidad en el diseño y desarrollo de infraestructura, procesos y experiencias. 
                                        <br></br>
                                        <br></br>
                                        Por eso, las empresas nacionales que estén preparadas para atenderles contarán con una ventaja competitiva y estarán a la altura de los tiempos gracias a una mayor accesibilidad.
                                        Ahora bien, sabemos que capacitar a todos los colaboradores en LESCO representaría una gran inversión de dinero y, especialmente, de tiempo; y debido a la rotación de personal, no garantiza que se adquieran habilidades que podrán ser aprovechadas muchos años.
                                        </div>
                                        <div className="description sc-mb-25 style:{{textalign:'justify'}}">
                                        <h3>La tecnología a favor de la inclusión.</h3>
                                            Ante esta realidad, han aparecido aplicaciones tecnológicas que permiten una comunicación fluida con la población sorda.

                                            En nuestro país, contamos con Helen Translate, un sistema que opera como un asistente virtual para la comunicación con personas sordas en instituciones públicas, plataformas de servicio y empresas.

                                            La plataforma cuenta con un chat interactivo, accesos rápidos para solicitar información o un servicio y un intérprete animado de la LESCO.

                                            Si usted tiene dudas sobre esta aplicación y desea mejorar la percepción de su marca a través de una atención al cliente inclusiva, accesible e integral, contáctenos. ¡helen puede ser su gran aliada!
                                        </div>
                                    </div>
                             
                                </div>
                            </div>
                            <div className='col-lg-2'></div>
                        </div>
                        
                    </div>
                </div>
            </section>
        </>
    );
};

export default Blog2;