import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import pagesBlogImg from '../../../assets/images/blog/art2.png'


const Blog2= () => {
    return (
        <>
            <section>
                <div className="sc-blog-details sc-pt-140 sc-md-pt-80 sc-pb-20 sc-md-pb-20">
                    <div className="container ">
                        <div className="row">
                            <div className='col-lg-2'></div>
                            <div className="col-lg-8">
                                <div className="blog-item">
                                    <div className="blog-image">
                                        <Link smooth to="#"><img src={pagesBlogImg} alt="Blog" /></Link>
                                    </div>
                                    <div className="blog-content p-0">
                                        <div className="blog_date">
                                            <ul>
                                                <li>Febrero</li>
                                                <li>2022</li>
                                            </ul>
                                        </div>

                                        <h3 className="blog-title sc-pt-15 sc-mb-15 fw-bold">
                                        Software para convertir texto a LESCO: novedades tecnológicas al servicio de las personas sordas
                                        </h3>
                                    </div>
                                </div>
                                <div className="details-content-area sc-md-mb-50">
                                    <div className="details-content">
                                        <div className="description sc-mb-25">
                                        Hoy queremos hablarle de helen translate, una aplicación de Inteligencia Artificial con múltiples funcionalidades y creada por costarricenses para ayudar a personas sordas y con problemas de audición.
                                        <br></br>
                                        <br></br>
                                        Esta plataforma tiene una serie de maravillosas ventajas como:
                                        <br></br>
                                        
                                        <li>Avatar animado llamada “helen” que convierte contenido escrito en español a lengua de señas.</li>
                                        <li>Aprende constantemente señas y expresiones nuevas, así como gramática de la lengua de señas y del lenguaje hablado.</li>
                                        <li>Puede integrar reconocimiento de voz y de imágenes.</li>
                                        <li>Adapta su apariencia a cada persona o empresa, para brindar un mejor servicio.</li>
                                        <li>Utiliza una combinación de tecnologías para existir en las plataformas móviles Android de Google y Apple iOS.</li>
                                        </div>
                                        <div className="description sc-mb-25">
                                        Precisamente uno de los servicios más aprovechados de helen translate es la capacidad de convertir texto a Lenguaje de Señas Costarricense (LESCO) para facilitar la comunicación entre personas sordas y personas que pueden oír.
                                        <br></br>
                                        <br></br>
                                        Este software funciona en cualquier dispositivo móvil como un teléfono inteligencia o tableta, y está enfocado en apoyar la comunicación transaccional, por lo que es una herramienta ideal para plataformas de servicio al cliente en empresas como bancos, hospitales o instituciones públicas, ofreciendo accesibilidad, confidencialidad de los datos y mayor autonomía a los clientes con problemas de audición.
                                        <br></br>
                                        <br></br>
                                        Si tiene alguna duda, contáctenos. La Inteligencia Artificial llegó para quedarse y nosotros la ponemos al servicio de las personas sordas y de una mayor inclusión social.
                                        </div>
                                     
                                    </div>
                             
                                </div>
                            </div>
                            <div className='col-lg-2'></div>
                        </div>
                        
                    </div>
                </div>
            </section>
        </>
    );
};

export default Blog2;