import React from 'react';


const BreadCums = (props) => {
    const { pageTitle,pageSubtitle} = props;
    
    return (
        <>
            <div className="sc-breadcrumbs">
            
                <div className="container">
                    
                    <div className="breadcrumbs-text white-color ">
                        <h1 className="page-title sc-sm-pt-20">{pageTitle}</h1>
                       
                       
                        <h3 className="heading-title white-color p-z-idex">{pageSubtitle}</h3>
                        
                        
                                            </div>
                </div>
            </div>
        </>
    );
};

export default BreadCums;