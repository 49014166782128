import React from 'react';
import { Fade } from 'react-reveal';
import logo from '../../assets/images/service/Cloud.png'
import gif from '../../assets/images/service/cloud.gif'
import bannerApps from '../../assets/images/bannerhelenCloud.png';
import botonplayer from '../../assets/images/botonplayer.png';

import cloud1 from '../../assets/glb/HelenCloud/cloud1.glb'
import cloud2 from '../../assets/glb/HelenCloud/cloud2.glb'
import cloud3 from '../../assets/glb/HelenCloud/cloud3.glb'
import cloud4 from '../../assets/glb/HelenCloud/cloud4.glb'
import cloud5 from '../../assets/glb/HelenCloud/cloud5.glb'
import cloud6 from '../../assets/glb/HelenCloud/cloud6.glb'
import cloud7 from '../../assets/glb/HelenCloud/cloud7.glb'
import cloud8 from '../../assets/glb/HelenCloud/cloud8.glb'
import cloud9 from '../../assets/glb/HelenCloud/cloud9.glb'
import cloud10 from '../../assets/glb/HelenCloud/cloud10.glb'
import cloud11 from '../../assets/glb/HelenCloud/cloud11.glb'
import cloud12 from '../../assets/glb/HelenCloud/cloud12.glb'
import cloud13 from '../../assets/glb/HelenCloud/cloud13.glb'
import cloud14 from '../../assets/glb/HelenCloud/cloud14.glb'
import cloud15 from '../../assets/glb/HelenCloud/cloud15.glb'
import cloud16 from '../../assets/glb/HelenCloud/cloud16.glb'
import cloud17 from '../../assets/glb/HelenCloud/cloud17.glb'
/*
const cloud1="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/cloud1.glb"
const cloud2="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/cloud2.glb"
const cloud3="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/cloud3.glb"
const cloud4="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/cloud4.glb"
const cloud5="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/cloud5.glb"
const cloud6="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/cloud6.glb"
const cloud7="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/cloud7.glb"
const cloud8="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/cloud8.glb"
const cloud9="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/cloud9.glb"
const cloud10="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/cloud10.glb"
const cloud11="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/cloud11.glb"
const cloud12="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/cloud12.glb"
const cloud13="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/cloud13.glb"
const cloud14="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/cloud14.glb"
const cloud15="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/cloud15.glb"
const cloud16="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/cloud16.glb"
const cloud17="https://helenwebpagenavigation.blob.core.windows.net/gltfwebsite/cloud17.glb"
*/

const ServiceFeature = (props) => {
    const translateClick = (model) => {
        var speed=document.getElementById("btnVelocity").innerText;
        //obtener el componente de modelviewer por medio del id
        var modelViewer = document.querySelector('#helenai');
        switch (model) {
            case 'cloud1':
                modelViewer.src=cloud1;
                break;
            case 'cloud2':
                modelViewer.src=cloud2;
                break;
            case 'cloud3':
                modelViewer.src=cloud3;
                break;
            case 'cloud4':
                modelViewer.src=cloud4;
                break;
            case 'cloud5':
                modelViewer.src=cloud5;
                break;
            case 'cloud6':
                modelViewer.src=cloud6;
                break;
            case 'cloud7':
                modelViewer.src=cloud7;
                break;
            case 'cloud8':
                modelViewer.src=cloud8;
                break;
            case 'cloud9':
                modelViewer.src=cloud9;
                break;
            case 'cloud10':
                modelViewer.src=cloud10;
                break;
            case 'cloud11':
                modelViewer.src=cloud11;
                break;
            case 'cloud12':
                modelViewer.src=cloud12;
                break;
            case 'cloud13':
                modelViewer.src=cloud13;
                break;
            case 'cloud14':
                modelViewer.src=cloud14;
                break;
            case 'cloud15':
                modelViewer.src=cloud15;
                break;
            case 'cloud16':
                modelViewer.src=cloud16;
                break;
            case 'cloud17':
                modelViewer.src=cloud17;
                break;
        }
     modelViewer.timeScale = speed;
     modelViewer.play({repetitions: 1});
      }

    return (
        <>
            <section className="sc-feature-section-area5 sc-pt-175 sc-md-pt-115 sc-pb-140 sc-md-pb-70">
                <div className="container">
                    <div className="sc-heading-area text-center ">
                        <img className="banner-1" src={logo} width="250" height="170" viewBox="0 0 70 48" fill="none" />
                    </div>
                    <div className="row"><div className="col-md-6 offset-md-3">
                        <div className="sc-heading-area text-center sc-mb-60">
                        <br></br>
                        <br></br>
                        <br></br>
                            <h2 className="heading-title translate" onClick={()=>translateClick('cloud1')}>¿Qué es helen Cloud? </h2>
                        </div>
                    </div>
                    </div>
                    <div className="row align-items-center " >


                        <div className="col-lg-12">
                            <div className="sc-feature-item">
                                <div className="sc-heading-area sc-mb-20 ">


                                    <div className="description sc-mb-25 translate" >

                                        <p className='parrafo' style={{textalign:'justify',textalignlast:'center'}} onClick={()=>translateClick('cloud2')}>
                                        helen-Cloud es un servicio en la nube que permite convertir cualquier contenido digital a una animación en lengua de señas.
                                        Adapte sus productos y servicios en accesibles para usuarios sordos. 
                                        Integre helen-Cloud en un sitio web, aplicación o cualquier canal digital existente de su empresa.
                                        </p>
                                        <br></br>
                                        <br></br>

                                    </div>
                                    <section className="sc-service-section-area2  sc-pb-130 sc-md-pb-70">
                                        <div className="container">
                                            <div className=" sc-mb-65 text-center">
                                                <div className="row">
                                                    <Fade bottom delay={150}>
                                                        <div className="col-lg-2 col-md-6">
                                                            <div className="service-content-box first-child text-center sc-sm-mb-30">
                                                                <div className="service_icon">
                                                                    <svg className="pulse" width="50" height="50" viewBox="0 0 100 100" fill="#5f4b8b" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M25.352,26.045c0.347-0.208,0.731-0.37,1.031-0.632c3.378-2.916,7.1-5.305,11.381-6.591
		c2.212-0.665,4.583-0.822,6.897-1.089c0.424-0.047,1.128,0.387,1.316,0.779c0.189,0.398,0.079,1.195-0.222,1.517
		c-0.538,0.577-1.262,1.097-2.006,1.345c-2.846,0.951-5.75,1.731-8.589,2.701c-0.673,0.229-1.164,0.968-1.761,1.445
		c-1.382,1.101-2.778,2.193-4.166,3.287c-0.219,0.174-0.425,0.366-0.637,0.551c0.042,0.076,0.085,0.153,0.127,0.23
		c0.71-0.299,1.404-0.641,2.133-0.883c2.274-0.773,4.514-1.815,6.856-2.161c2.021-0.3,4.167,0.168,6.248,0.39
		c0.484,0.055,0.97,0.439,1.381,0.766c1.52,1.196,3.004,2.436,4.511,3.656c0.518,0.42,0.523,0.824,0.133,1.371
		c-0.926,1.293-2.098,1.699-3.469,0.9c-1.089-0.635-2.065-1.469-3.058-2.254c-0.528-0.418-0.999-0.57-1.722-0.454
		c-1.379,0.216-2.796,0.175-4.191,0.308c-0.513,0.048-1.091,0.166-1.499,0.451c-1.937,1.373-3.842,2.791-5.715,4.25
		c-0.352,0.273-0.506,0.807-0.594,1.381c0.188-0.084,0.394-0.143,0.561-0.256c1.53-1.043,3.307-1.186,5.062-1.407
		c0.559-0.071,1.13-0.071,1.685-0.165c2.269-0.383,4.003,0.707,5.675,2.002c1.375,1.064,2.769,2.104,4.134,3.179
		c0.803,0.632,1.214,1.458,1.207,2.514c-0.013,1.767,0.097,3.54-0.549,5.243c-0.076,0.199-0.116,0.576-0.008,0.66
		c1.075,0.823,0.371,1.738,0.089,2.547c-0.599,1.737-1.318,3.428-1.995,5.137c-0.647,1.627-1.637,2.716-3.481,3.239
		c-4.077,1.161-8.08,2.583-12.104,3.925c-1.645,0.551-3.251,0.766-4.998,0.344c-3.317-0.797-6.583-0.447-9.708,0.938
		c-1.453,0.646-2.867,1.382-4.349,2.103c-1.701-2.443-2.704-5.113-3.327-7.945c-0.735-3.33-0.78-6.69-0.372-10.061
		c0.035-0.291,0.299-0.699,0.558-0.803c3.312-1.352,4.841-4.121,5.937-7.281c1.302-3.749,1.923-7.824,4.223-11.121
		c2.549-3.656,4.515-7.73,7.852-10.85c1.645-1.539,2.97-3.417,4.431-5.148c0.682-0.807,0.996-0.795,1.582,0.094
		c0.668,1.012,0.507,2.094-0.318,3.133c-1.992,2.516-3.942,5.062-5.896,7.605c-0.205,0.271-0.318,0.61-0.474,0.918
		C25.219,25.915,25.285,25.978,25.352,26.045z M39.713,54.342c0.811,0,1.543,0.219,1.945-0.646c0.79-1.7,1.992-3.079,3.444-4.241
		c0.432-0.348,0.389-0.635,0.144-1.1c-0.704-1.348-1.305-2.75-1.996-4.104c-0.194-0.374-0.504-0.747-0.858-0.975
		c-1.229-0.785-2.48-1.543-3.762-2.234c-0.341-0.186-0.872-0.248-1.231-0.125c-0.973,0.338-1.89,0.834-2.848,1.221
		c-0.865,0.35-1.766,0.602-2.628,0.953c-2.055,0.836-2.553,2.734-3.158,4.598c-0.405,1.242-0.131,2.152,0.758,3.075
		c1.176,1.218,2.26,2.532,3.317,3.858c0.374,0.47,0.714,0.496,1.203,0.334C35.93,54.331,37.847,53.971,39.713,54.342z"/>
                                                                        <path d="M74.49,73.967c-0.655,0.49-1.343,0.944-1.957,1.481c-3.015,2.646-6.487,4.538-10.255,5.753
		c-2.191,0.709-4.569,0.902-6.881,1.139c-1.312,0.136-2.023-1.223-1.2-2.281c0.511-0.652,1.351-1.182,2.15-1.454
		c2.43-0.824,4.931-1.438,7.363-2.261c0.881-0.299,1.69-0.891,2.445-1.459c1.571-1.191,3.085-2.458,4.618-3.695
		c0.225-0.182,0.435-0.381,0.529-0.783c-0.837,0.357-1.653,0.771-2.511,1.064c-2.113,0.729-4.203,1.715-6.382,2.002
		c-2.069,0.274-4.243-0.164-6.362-0.385c-0.479-0.049-0.947-0.461-1.365-0.777c-1.328-1.01-2.632-2.049-3.939-3.086
		c-1.345-1.068-1.142-2.455,0.542-2.986c0.656-0.207,1.585-0.141,2.18,0.184c1.187,0.65,2.215,1.592,3.347,2.354
		c0.317,0.217,0.769,0.348,1.151,0.332c1.466-0.06,2.936-0.146,4.391-0.33c0.665-0.081,1.394-0.277,1.933-0.654
		c1.835-1.279,3.62-2.637,5.375-4.027c0.368-0.291,0.531-0.842,0.692-1.375c-2.368,1.559-5.022,1.568-7.64,1.78
		c-1.722,0.138-3.213-0.276-4.568-1.384c-1.756-1.439-3.613-2.756-5.358-4.207c-0.414-0.345-0.79-1.022-0.781-1.539
		c0.033-1.757,0.225-3.513,0.398-5.264c0.057-0.582,0.367-1.139-0.247-1.656c-0.175-0.146-0.18-0.639-0.096-0.922
		c0.209-0.703,0.522-1.371,0.778-2.059c0.491-1.312,0.975-2.627,1.452-3.949c0.649-1.801,1.736-2.988,3.709-3.551
		c4.043-1.165,8.02-2.555,12.019-3.873c1.676-0.557,3.299-0.788,5.092-0.312c3.496,0.927,6.889,0.274,10.134-1.2
		c1.244-0.568,2.443-1.232,3.732-1.887c1.471,1.793,2.239,3.92,2.917,6.08c1.224,3.896,1.276,7.885,0.905,11.908
		c-0.082,0.898-0.825,1.02-1.374,1.251c-1.989,0.841-3.227,2.423-4.062,4.261c-0.906,2-1.605,4.108-2.261,6.207
		c-0.919,2.944-1.89,5.848-3.626,8.441c-1.318,1.967-2.508,4.027-3.907,5.933c-1.142,1.556-2.514,2.942-3.776,4.409
		c-1.347,1.564-2.683,3.138-4.029,4.703c-0.716,0.83-1.061,0.798-1.65-0.145c-0.64-1.025-0.456-2.031,0.413-3.117
		c1.979-2.482,3.914-4.997,5.849-7.514c0.2-0.259,0.275-0.615,0.409-0.926C74.685,74.116,74.587,74.044,74.49,73.967z M54.765,51.12
		c0.635,1.527,1.283,3.151,1.998,4.74c0.157,0.352,0.513,0.662,0.851,0.877c1.237,0.773,2.529,1.465,3.75,2.264
		c0.445,0.292,0.741,0.361,1.247,0.142c1.493-0.655,3.02-1.235,4.544-1.819c1.476-0.56,2.797-1.305,3.38-2.89
		c0.299-0.813,0.575-1.642,0.788-2.479c0.233-0.914,0.105-1.72-0.615-2.483c-1.28-1.351-2.428-2.819-3.668-4.209
		c-0.159-0.182-0.572-0.297-0.799-0.225c-2.13,0.676-4.274,0.991-6.516,0.646c-0.408-0.066-1.043,0.256-1.321,0.604
		C57.123,47.884,55.934,49.55,54.765,51.12z"/>
                                                                    </svg>
                                                                </div>
                                                                <h4 className="service_title translate"  onClick={()=>translateClick('cloud3')}>Avatar intérprete</h4>
                                                            </div>
                                                        </div>
                                                    </Fade>
                                                    <Fade bottom delay={250}>
                                                        <div className="col-lg-2 col-md-6">
                                                            <div className="service-content-box text-center sc-sm-mb-30">
                                                                <div className="service_icon">
                                                                    <svg className="pulse" width="30" height="30" viewBox="0 0 36 36" fill="#5f4b8b" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M30.61,24.52a17.16,17.16,0,0,0-25.22,0,1.51,1.51,0,0,0-.39,1v6A1.5,1.5,0,0,0,6.5,33h23A1.5,1.5,0,0,0,31,31.5v-6A1.51,1.51,0,0,0,30.61,24.52Z" className="clr-i-solid clr-i-solid-path-1"></path><circle cx="18" cy="10" r="7" className="clr-i-solid clr-i-solid-path-2"></circle>
                                                                    <rect x="0" y="0" width="36" height="36" fill-opacity="0"/>   
                                                                    </svg>
                                                                </div>
                                                                <h4 className="service_title translate"  onClick={()=>translateClick('cloud4')}>Avatar personalizado a preferencia</h4>
                                                            </div>
                                                        </div>
                                                    </Fade>
                                                    <Fade bottom delay={350}>
                                                        <div className="col-lg-2 col-md-6">
                                                            <div className="service-content-box text-center sc-sm-mb-30">
                                                                <div className="service_icon">
                                                                    <svg className="pulse" width="28" height="28" viewBox="0 0 57 57" fill="#5f4b8b" xmlns="http://www.w3.org/2000/svg">
                                                                    <g>
	<path d="M22.66,0H3.34C1.498,0,0,1.498,0,3.34v19.32C0,24.502,1.498,26,3.34,26h19.32c1.842,0,3.34-1.498,3.34-3.34V3.34
		C26,1.498,24.502,0,22.66,0z"/>
	<path d="M33.34,26h19.32c1.842,0,3.34-1.498,3.34-3.34V3.34C56,1.498,54.502,0,52.66,0H33.34C31.498,0,30,1.498,30,3.34v19.32
		C30,24.502,31.498,26,33.34,26z"/>
	<path d="M22.66,30H3.34C1.498,30,0,31.498,0,33.34v19.32C0,54.502,1.498,56,3.34,56h19.32c1.842,0,3.34-1.498,3.34-3.34V33.34
		C26,31.498,24.502,30,22.66,30z"/>
	<path d="M55,41H45V31c0-1.104-0.896-2-2-2s-2,0.896-2,2v10H31c-1.104,0-2,0.896-2,2s0.896,2,2,2h10v10c0,1.104,0.896,2,2,2
		s2-0.896,2-2V45h10c1.104,0,2-0.896,2-2S56.104,41,55,41z"/>
</g>
                                                                    </svg>
                                                                </div>
                                                                <h4 className="service_title translate"  onClick={()=>translateClick('cloud5')}>Versatilidad de implementación </h4>
                                                            </div>
                                                        </div>
                                                    </Fade>
                                                    <Fade bottom delay={450}>
                                                        <div className="col-lg-2 col-md-6">
                                                            <div className="service-content-box text-center">
                                                                <div className="service_icon">
                                                                    <svg className="pulse" width="35" height="35" viewBox="0 0 32 32" fill="#5f4b8b" xmlns="http://www.w3.org/2000/svg">
                                                                    <polygon fill="none" stroke="#5f4b8b" stroke-width="2" stroke-miterlimit="10" points="25,28 7,28 7,4 19,4 25,10 "/>
<polyline fill="none" stroke="#5f4b8b" stroke-width="2" stroke-miterlimit="10" points="19,4 19,10 25,10 "/>
<rect x="11" y="21" width="10" height="2"/>
<rect x="11" y="17" width="10" height="2"/>
<rect x="11" y="13" width="10" height="2"/>
                                                                    </svg>
                                                                </div>
                                                                <h4 className="service_title translate"  onClick={()=>translateClick('cloud6')}>
                                                                    Sin límite de cantidad de texto</h4>
                                                            </div>
                                                        </div>
                                                    </Fade>
                                                    
                                                
                                                    <Fade bottom delay={450}>
                                                        <div className="col-lg-2 col-md-6">
                                                            <div className="service-content-box text-center">
                                                                <div className="service_icon">
                                                                    <svg className="pulse" fill="#5f4b8b" width="45" height="45" viewBox="-2 0 19 19" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M14.048 10.55a6.548 6.548 0 1 1-7.102-6.525V3.16l-.466.002h-.002a.554.554 0 0 1-.002-1.109l2.044-.007h.002a.554.554 0 0 1 .001 1.108l-.469.002v.868a6.517 6.517 0 0 1 3.614 1.474l.634-.634a.554.554 0 0 1 .784.783l-.626.626a6.522 6.522 0 0 1 1.588 4.276zm-1.059 0A5.49 5.49 0 1 0 7.5 16.04a5.49 5.49 0 0 0 5.49-5.49zm-1.108 0a4.364 4.364 0 1 1-.344-1.705 4.352 4.352 0 0 1 .344 1.705zM9.38 11.668a1.375 1.375 0 0 0-.34-.904 1.848 1.848 0 0 0-.512-.407l-.002-.001a2.156 2.156 0 0 0-.532-.193 2.317 2.317 0 0 0-.487-.05 1.78 1.78 0 0 1-.296-.023 1.126 1.126 0 0 1-.31-.1.733.733 0 0 1-.234-.188.467.467 0 0 1-.11-.281.475.475 0 0 1 .265-.4 1.346 1.346 0 0 1 .692-.185 1.41 1.41 0 0 1 .245.04l.03.007a1.546 1.546 0 0 1 .294.103.834.834 0 0 1 .24.164.475.475 0 1 0 .67-.672 1.782 1.782 0 0 0-.52-.358 2.454 2.454 0 0 0-.483-.165l-.015-.003v-.496a.475.475 0 0 0-.95 0v.482a2.184 2.184 0 0 0-.71.28 1.423 1.423 0 0 0-.709 1.203 1.404 1.404 0 0 0 .323.88 1.666 1.666 0 0 0 .547.439 2.05 2.05 0 0 0 .579.187 2.708 2.708 0 0 0 .452.036 1.37 1.37 0 0 1 .285.029 1.192 1.192 0 0 1 .292.105.905.905 0 0 1 .24.192.43.43 0 0 1 .106.279c0 .062 0 .207-.244.365a1.363 1.363 0 0 1-.706.196 2.182 2.182 0 0 1-.307-.038 1.493 1.493 0 0 1-.287-.091.604.604 0 0 1-.225-.173.475.475 0 1 0-.718.622 1.536 1.536 0 0 0 .59.433 2.325 2.325 0 0 0 .476.145l.016.003v.494a.475.475 0 0 0 .95 0v-.499a2.203 2.203 0 0 0 .728-.295 1.355 1.355 0 0 0 .677-1.162z"/>
                                                                    </svg>
                                                                </div>
                                                                <h4 className="service_title translate"  onClick={()=>translateClick('cloud7')}>Traducción en segundos</h4>
                                                            </div>
                                                        </div>
                                                    </Fade>
                                                    <Fade bottom delay={450}>
                                                        <div className="col-lg-2 col-md-6">
                                                            <div className="service-content-box text-center">
                                                                <div className="service_icon">
                                                                    <svg className="pulse" fill="#5f4b8b" width="35" height="35" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                    <path fill-rule="evenodd" d="M13,2 C14.5976809,2 15.9036609,3.24891996 15.9949073,4.82372721 L16,5 L16,8 L19,8 C20.5976809,8 21.9036609,9.24891996 21.9949073,10.8237272 L22,11 L22,19 C22,20.5976809 20.75108,21.9036609 19.1762728,21.9949073 L19,22 L11,22 C9.40231912,22 8.09633912,20.75108 8.00509269,19.1762728 L8,19 L8,16 L5,16 C3.40231912,16 2.09633912,14.75108 2.00509269,13.1762728 L2,13 L2,5 C2,3.40231912 3.24891996,2.09633912 4.82372721,2.00509269 L5,2 L13,2 Z M19,10 L16,10 L16,13 C16,14.5976809 14.75108,15.9036609 13.1762728,15.9949073 L13,16 L10,16 L10,19 C10,19.5128358 10.3860402,19.9355072 10.8833789,19.9932723 L11,20 L19,20 C19.5128358,20 19.9355072,19.6139598 19.9932723,19.1166211 L20,19 L20,11 C20,10.4871642 19.6139598,10.0644928 19.1166211,10.0067277 L19,10 Z M13,4 L5,4 C4.48716416,4 4.06449284,4.38604019 4.00672773,4.88337887 L4,5 L4,13 C4,13.5128358 4.38604019,13.9355072 4.88337887,13.9932723 L5,14 L8,14 L8,11 C8,9.40231912 9.24891996,8.09633912 10.8237272,8.00509269 L11,8 L14,8 L14,5 C14,4.48716416 13.6139598,4.06449284 13.1166211,4.00672773 L13,4 Z M14,10 L11,10 C10.4871642,10 10.0644928,10.3860402 10.0067277,10.8833789 L10,11 L10,14 L13,14 C13.5128358,14 13.9355072,13.6139598 13.9932723,13.1166211 L14,13 L14,10 Z"/>
                                                                    </svg>
                                                                </div>
                                                                <h4 className="service_title translate"  onClick={()=>translateClick('cloud8')}>Compatible con productos existentes</h4>
                                                            </div>
                                                        </div>
                                                    </Fade>
                                                </div>
                                            </div>

                                        </div>
                                    </section>
                                </div>


                            </div>
                        </div>

                    </div>

                    
                    <div className="sc-heading-area text-center sc-mb-60 ">


                        <h1 className="heading-title translate" onClick={()=>translateClick('cloud9')} style={{color:'#5f4b8b'}}>Conversión de contenido digital a lengua de señas en segundos. </h1>
                        <br></br>
                        <br></br>

                        <h2 className="heading-title translate" onClick={()=>translateClick('cloud10')}>¿Cómo funciona? </h2>
                        <br></br>
                        <br></br>
                        <p className="translate" onClick={()=>translateClick('cloud11')}>
                            helen-Cloud permite desde cualquier canal digital, ya sea sitio web o aplicación realizar una solicitud 
                            de conversión a nuestro servicio en la nube el cual, realizará el procesamiento
                            y responderá con un video del contenido traducido en lengua de señas.
                        </p>

                    </div>
                    <div className="row align-items-center">


                        <div className="col-lg-12">
                            <div className="sc-feature-item">
                                <div className="sc-heading-area sc-mb-20">

                                    <div className="description sc-mb-25 text-center">
                                        <br></br> <p> </p> 
                                        <img className="banner-1 " src={gif} width="90%" height="90%" fill="none" />
                                    </div>

                                    <section className="sc-service-section-area2  sc-pb-130 sc-md-pb-70">
                                        <div className="container">
                                            <div className=" sc-mb-65 text-center">

                                            </div>

                                        </div>
                                    </section>
                                </div>


                            </div>
                        </div>

                    </div>

                    <div className="sc-heading-area text-center sc-mb-60">


                        <h2 className="heading-title translate" onClick={()=>translateClick('cloud12')}>¿Cuáles son sus beneficios? </h2>
                    </div>
                <div className="container sc-heading-area sc-mb-60 ">
                    <div className="row">
                        <Fade bottom delay={100}>
                       
                            <div className="col-lg-6">
                                <div className="sc-feature-item sc-pr-90 sc-feature-section-area">

                                <div className="feature-content-box">
                                        <div className="feature-icon">
                                            <svg width="28" height="28" viewBox="0 0 16 16" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M3 14h4v.91l.09.09H2.5l-.5-.5v-13l.5-.5h7.72l.35.14 3.28 3.3.15.36v2.54a3.1 3.1 0 0 0-1-.94V6H9.5L9 5.5V2H3v12zm10-9l-3-3v3h3zm.5 4v1h1l.5.5v4l-.5.5h-6l-.5-.5v-4l.5-.5h1V9a2 2 0 0 1 4 0zm-2.707-.707A1 1 0 0 0 10.5 9v1h2V9a1 1 0 0 0-1.707-.707zM9 11v3h5v-3H9z"/>
                                            </svg>
                                        </div>
                                        <div className="sc-feature-text translate" onClick={()=>translateClick('cloud13')}>
                                            <h4 className="feature-title">Confidencialidad</h4>
                                            <div className="description sc-mb-25">
                                            Protegemos los datos e información compartida en las traducciones.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="feature-content-box">
                                        <div className="feature-icon">
                                            <svg width="35" height="35" viewBox="0 0 24 24" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14,18 L4.5,18 C3.11928813,18 2,16.8807119 2,15.5 C2,15.2238576 2.22385763,15 2.5,15 L4,15 L4,6.48864223 C4,5.10793035 5.11928813,3.98864223 6.5,3.98864223 L17.5087137,3.98864223 C18.8894256,3.98864223 20.0087137,5.10793035 20.0087137,6.48864223 L20.0087137,8 L20.5,8 C21.3284271,8 22,8.67157288 22,9.5 L22,19.5104121 C22,20.3388393 21.3284271,21.0104121 20.5,21.0104121 L15.5,21.0104121 C14.6715729,21.0104121 14,20.3388393 14,19.5104121 L14,18 L14,18 Z M14,17 L14,16 L3.08535285,16 C3.29127106,16.5825962 3.84689059,17 4.5,17 L14,17 L14,17 Z M14,15 L14,14 L6.5,14 C6.22385763,14 6,13.7761424 6,13.5 L6,6.5 C6,6.22385763 6.22385763,6 6.5,6 L17.5,6 C17.7761424,6 18,6.22385763 18,6.5 L18,8 L19.0087137,8 L19.0087137,6.48864223 C19.0087137,5.6602151 18.3371408,4.98864223 17.5087137,4.98864223 L6.5,4.98864223 C5.67157288,4.98864223 5,5.6602151 5,6.48864223 L5,15 L14,15 Z M14,13 L14,9.5 C14,8.67157288 14.6715729,8 15.5,8 L17,8 L17,7 L7,7 L7,13 L14,13 Z M15.5,9 C15.2238576,9 15,9.22385763 15,9.5 L15,19.5104121 C15,19.7865545 15.2238576,20.0104121 15.5,20.0104121 L20.5,20.0104121 C20.7761424,20.0104121 21,19.7865545 21,19.5104121 L21,9.5 C21,9.22385763 20.7761424,9 20.5,9 L15.5,9 Z M17.5,19 C17.2238576,19 17,18.7761424 17,18.5 C17,18.2238576 17.2238576,18 17.5,18 L18.5,18 C18.7761424,18 19,18.2238576 19,18.5 C19,18.7761424 18.7761424,19 18.5,19 L17.5,19 Z"/>
                                            </svg>
                                        </div>
                                        <div className="sc-feature-text translate" onClick={()=>translateClick('cloud14')}>
                                            <h4 className="feature-title">Compatibilidad</h4>
                                            <div className="description sc-mb-25">
                                                Lenguaje agnóstico, compatible con cualquier tecnología.
                                               </div>
                                        </div>
                                    </div>
                                  

                                    <div className="feature-content-box">
                                        <div className="feature-icon">
                                            <svg width="28" height="24" viewBox="0 0 35 35" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.933,15.055H3.479A3.232,3.232,0,0,1,.25,11.827V3.478A3.232,3.232,0,0,1,3.479.25h8.454a3.232,3.232,0,0,1,3.228,3.228v8.349A3.232,3.232,0,0,1,11.933,15.055ZM3.479,2.75a.73.73,0,0,0-.729.728v8.349a.73.73,0,0,0,.729.728h8.454a.729.729,0,0,0,.728-.728V3.478a.729.729,0,0,0-.728-.728Z"/><path d="M11.974,34.75H3.52A3.233,3.233,0,0,1,.291,31.521V23.173A3.232,3.232,0,0,1,3.52,19.945h8.454A3.232,3.232,0,0,1,15.2,23.173v8.348A3.232,3.232,0,0,1,11.974,34.75ZM3.52,22.445a.73.73,0,0,0-.729.728v8.348a.73.73,0,0,0,.729.729h8.454a.73.73,0,0,0,.728-.729V23.173a.729.729,0,0,0-.728-.728Z"/><path d="M31.522,34.75H23.068a3.233,3.233,0,0,1-3.229-3.229V23.173a3.232,3.232,0,0,1,3.229-3.228h8.454a3.232,3.232,0,0,1,3.228,3.228v8.348A3.232,3.232,0,0,1,31.522,34.75Zm-8.454-12.3a.73.73,0,0,0-.729.728v8.348a.73.73,0,0,0,.729.729h8.454a.73.73,0,0,0,.728-.729V23.173a.729.729,0,0,0-.728-.728Z"/><path d="M27.3,15.055a7.4,7.4,0,1,1,7.455-7.4A7.437,7.437,0,0,1,27.3,15.055Zm0-12.3a4.9,4.9,0,1,0,4.955,4.9A4.935,4.935,0,0,0,27.3,2.75Z"/>
                                            </svg>
                                        </div>
                                        <div className="sc-feature-text translate" onClick={()=>translateClick('cloud15')}>
                                            <h4 className="feature-title">Versatilidad</h4>
                                            <div className="description sc-mb-25">
                                                Fácil implementación en canales existentes como páginas web o aplicaciones.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           
                        </Fade>

                        <Fade bottom delay={200}>
                        <div className="row col-lg-6">
                                <div className="sc-feature-item sc-pr-90 sc-feature-section-area ">

                                    <div className="feature-content-box">
                                        <div className="feature-icon">
                                            <svg width="35" height="35" viewBox="0 0 256 256" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M63.1,106.3H9.9c-2.7,0-4.9,2.2-4.9,4.9c0,2.7,2.2,4.9,4.9,4.9h53.2c2.7,0,4.9-2.2,4.9-4.9C68,108.5,65.8,106.3,63.1,106.3   z"/>

<path d="M63.1,135.2H38.6c-2.7,0-4.9,2.2-4.9,4.9c0,2.7,2.2,4.9,4.9,4.9h24.5c2.7,0,4.9-2.2,4.9-4.9   C68,137.4,65.8,135.2,63.1,135.2z"/>

<path d="M63.1,164.1H22.7c-2.7,0-4.9,2.2-4.9,4.9c0,2.7,2.2,4.9,4.9,4.9h40.4c2.7,0,4.9-2.2,4.9-4.9   C68,166.3,65.8,164.1,63.1,164.1z"/>

<path d="M242.9,103.9c-4-8.5-9.3-16.2-15.8-22.9c-3.1-3.3-6.6-6.3-10.2-9l6.4-8.5c1.6-2.2,1.2-5.3-1-6.9c-2.2-1.6-5.3-1.2-6.9,1   l-6.8,9c-4.5-2.7-9.3-4.9-14.2-6.7c-4.3-1.5-8.7-2.7-13.2-3.6c-2.9-0.5-5.9-0.9-8.8-1.1V40.3h9.9c2.7,0,4.9-2.2,4.9-4.9   c0-2.7-2.2-4.9-4.9-4.9h-29.7c-2.7,0-4.9,2.2-4.9,4.9c0,2.7,2.2,4.9,4.9,4.9h9.9v14.5c-11.4,0.4-22.7,3.1-33.1,7.9   c-8.5,4-16.2,9.2-23,15.7c-9.5,9-16.8,20.3-21.3,32.6c-1.6,4.3-2.8,8.9-3.7,13.5c-0.9,4.6-1.3,9.2-1.5,13.8   c-0.3,13.2,2.5,26.4,8.2,38.4c4,8.4,9.2,16,15.7,22.7c9.1,9.5,20.4,16.8,32.7,21.2c4.3,1.5,8.7,2.7,13.2,3.6   c4.5,0.8,9,1.3,13.6,1.4c0.7,0,1.5,0,2.2,0c12.4,0,24.8-2.7,36-8c8.4-3.9,16.1-9.1,22.8-15.5c9.6-9.1,17-20.4,21.5-32.8   c1.6-4.3,2.8-8.9,3.6-13.4c0.9-4.6,1.4-9.3,1.5-13.9C251.3,128.9,248.5,115.7,242.9,103.9z M239.8,154   C239.8,154,239.8,154,239.8,154c-0.8,4.1-1.8,8-3.2,11.9c-4,10.9-10.5,21-19,29c-5.9,5.6-12.7,10.3-20.2,13.7   c-10.5,4.9-22.3,7.4-33.7,7c-4-0.1-8-0.5-12-1.3c-4-0.7-7.9-1.8-11.7-3.1c-10.9-3.9-20.9-10.4-28.9-18.8   c-5.7-5.9-10.4-12.7-13.9-20.1c-5-10.5-7.5-22.3-7.2-33.9c0.1-4.1,0.5-8.2,1.3-12.2c0.8-4.1,1.9-8.1,3.2-11.9   c3.9-10.9,10.4-20.8,18.8-28.8c6-5.7,12.8-10.4,20.4-13.9c9.9-4.6,20.9-7,31.8-7c0.6,0,1.3,0,1.9,0c4,0.1,8,0.5,12,1.3   c4,0.7,7.9,1.8,11.7,3.2C202,73,212,79.4,220,87.8c5.7,6,10.4,12.8,14,20.3c4.9,10.5,7.4,22.1,7.1,33.7   C241,145.8,240.6,150,239.8,154z"/>

<path d="M196.1,105.9L175,125c-1.1-0.7-2.2-1.2-3.4-1.7c-0.9-0.3-1.8-0.6-2.7-0.7c-1-0.2-1.9-0.3-2.8-0.3c-2.7-0.1-5.5,0.5-8,1.7   c-1.8,0.8-3.4,1.9-4.8,3.3c-2,1.9-3.5,4.2-4.4,6.8c-0.3,0.9-0.6,1.8-0.8,2.8c-0.2,1-0.3,1.9-0.3,2.9c-0.1,2.8,0.5,5.5,1.7,8   c0.8,1.8,1.9,3.3,3.3,4.7c1.9,2,4.3,3.5,6.8,4.4c0.9,0.3,1.8,0.6,2.7,0.7c0.9,0.2,1.9,0.3,2.8,0.3c0.2,0,0.3,0,0.5,0   c2.6,0,5.2-0.6,7.5-1.7c1.7-0.8,3.4-1.9,4.8-3.2c2-1.9,3.5-4.3,4.5-6.8c0.3-0.9,0.6-1.8,0.8-2.8c0.2-1,0.3-1.9,0.3-2.9   c0.1-2.7-0.5-5.5-1.7-8c0-0.1-0.1-0.1-0.1-0.2l21.2-19.1c2-1.8,2.2-5,0.4-7C201.3,104.3,198.2,104.1,196.1,105.9z M173.4,141.6   C173.4,141.6,173.4,141.6,173.4,141.6c-0.1,0.4-0.2,0.9-0.3,1.3c-0.4,1.1-1.1,2.2-2,3c-0.6,0.6-1.3,1.1-2.1,1.4   c-1.1,0.5-2.3,0.7-3.5,0.7c-0.4,0-0.9-0.1-1.3-0.1c-0.4-0.1-0.8-0.2-1.2-0.3c-1.1-0.4-2.2-1.1-3-2c-0.6-0.6-1.1-1.3-1.5-2.1   c-0.5-1.1-0.8-2.3-0.8-3.5c0-0.4,0.1-0.9,0.1-1.3c0.1-0.4,0.2-0.9,0.3-1.3c0.4-1.1,1.1-2.2,2-3c0.6-0.6,1.3-1.1,2.1-1.5   c1.1-0.5,2.2-0.7,3.3-0.7c0.1,0,0.1,0,0.2,0c0.4,0,0.8,0.1,1.3,0.1c0.4,0.1,0.8,0.2,1.2,0.3c1.2,0.4,2.2,1.1,3,2   c0.6,0.6,1.1,1.4,1.5,2.1c0.5,1.1,0.8,2.3,0.7,3.5C173.5,140.7,173.4,141.2,173.4,141.6z"/>
                                            </svg>
                                        </div>
                                        <div className="sc-feature-text translate" onClick={()=>translateClick('cloud16')}>
                                            <h4 className="feature-title">Eficiencia</h4>
                                            <div className="description sc-mb-25">
                                                Traducción en segundos. Reducción del tiempo de atención de personas sordas.
                                               </div>
                                        </div>
                                    </div>
                                    <div className="feature-content-box">
                                        <div className="feature-icon">
                                            <svg width="35" height="35" viewBox="0 0 512 512" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                                           <g>
	<g>
		<path d="M260.348,352.204c-4.503,0-8.153,3.65-8.153,8.153v17.39c0,4.503,3.65,8.153,8.153,8.153s8.153-3.65,8.153-8.153v-17.39
			C268.501,355.854,264.851,352.204,260.348,352.204z"/>
	</g>
</g>
<g>
	<g>
		<path d="M507.064,235.387c-3.182-3.182-7.411-4.934-11.912-4.934c-0.001,0-0.002,0-0.003,0l-20.929,0.003
			c-0.265,0-0.447-0.298-0.503-0.574c-5.753-28.218-18.654-54.82-37.31-76.932c-18.032-21.372-41.127-38.18-66.88-48.737
			c-4.154-17.536-12.925-35.616-30.222-49.207c-16.746-13.158-33.612-15.69-34.321-15.792c-2.336-0.333-4.709,0.363-6.495,1.912
			c-1.786,1.549-2.811,3.795-2.811,6.159v44.026h-29.588c-7.312-25.09-30.5-43.482-57.919-43.482
			c-28.001,0-51.603,19.177-58.376,45.085c-40.312,5.491-77.4,25.021-104.92,55.386C15.937,180.233,0,221.569,0,264.696
			c0,96.801,50.255,175.564,65.659,197.397c4.758,6.745,12.551,10.773,20.846,10.773h34.701c14.086,0,25.546-11.46,25.546-25.546
			v-11.285c8.781,1.352,17.702,2.045,26.633,2.045h78.811v9.24c0,14.086,11.46,25.546,25.546,25.546h38.117
			c11.966,0,22.471-8.493,24.978-20.193l4.184-19.524c53.584-13.049,96.667-50.641,117.872-99.424h32.258
			c9.291,0,16.849-7.558,16.849-16.849v-69.572C512,242.801,510.248,238.57,507.064,235.387z M208.17,64.136
			c24.276,0,44.025,19.75,44.025,44.026c0,6.208-1.329,12.323-3.833,17.936h-80.385c-2.503-5.615-3.833-11.728-3.833-17.936
			C164.144,83.885,183.894,64.136,208.17,64.136z M495.694,316.874c0,0.3-0.243,0.544-0.544,0.544h-26.128
			c4.441-13.904,7.174-28.526,7.956-43.604c0.234-4.496-3.223-8.331-7.72-8.564c-4.497-0.228-8.331,3.223-8.564,7.72
			c-3.454,66.592-48.778,123.449-111.832,142.252l11.391-53.155c0.944-4.403-1.861-8.737-6.264-9.68
			c-4.4-0.946-8.737,1.861-9.68,6.264l-19.416,90.604c-0.907,4.232-4.707,7.304-9.034,7.304h-38.119c-5.095,0-9.24-4.145-9.24-9.24
			v-34.786c0-4.503-3.65-8.153-8.153-8.153s-8.153,3.65-8.153,8.153v9.24h-78.811c-8.945,0-17.874-0.769-26.633-2.262v-59.155
			c0-4.503-3.65-8.153-8.153-8.153s-8.153,3.65-8.153,8.153v86.964c0,5.095-4.145,9.24-9.24,9.24H86.505
			c-3.048,0-5.79-1.409-7.522-3.866c-14.703-20.843-62.677-95.997-62.677-187.999c0-39.072,14.437-76.52,40.651-105.445
			c23.99-26.473,55.999-43.853,90.907-49.561c0.141,5.587,1.057,11.111,2.701,16.407h-3.27c-4.503,0-8.153,3.65-8.153,8.153
			s3.65,8.153,8.153,8.153h121.75c4.503,0,8.153-3.65,8.153-8.153s-3.65-8.153-8.153-8.153h-3.271
			c1.795-5.779,2.727-11.828,2.727-17.936c0-0.183-0.012-0.362-0.014-0.544h27.19v17.936c0,4.503,3.65,8.153,8.153,8.153
			c4.503,0,8.153-3.65,8.153-8.153V57.99c15.469,5.854,44.569,23.835,44.569,76.26c0,4.503,3.65,8.153,8.153,8.153
			s8.153-3.65,8.153-8.153c0-3.454-0.125-7.101-0.411-10.87c19.667,9.58,37.311,23.272,51.498,40.087
			c16.9,20.031,28.586,44.123,33.795,69.673c1.609,7.895,8.541,13.624,16.483,13.623l20.929-0.002c0.055,0,0.225,0,0.384,0.159
			c0.159,0.159,0.159,0.327,0.159,0.384V316.874z"/>
	</g>
</g>
<g>
	<g>
		<path d="M424.521,235.759c-5.06-13.576-18.455-22.698-33.327-22.698c-14.873,0-28.266,9.121-33.329,22.698
			c-1.573,4.219,0.572,8.914,4.791,10.487c4.22,1.575,8.915-0.572,10.487-4.791c2.696-7.23,9.95-12.088,18.05-12.088
			s15.355,4.858,18.05,12.088c1.223,3.281,4.333,5.307,7.64,5.307c0.947,0,1.909-0.166,2.847-0.516
			C423.949,244.673,426.094,239.977,424.521,235.759z"/>
	</g>
</g>
<g>
	<g>
		<path d="M216.323,258.479v-41.664c15.994,1.944,27.176,9.354,27.176,16.085c0,4.503,3.65,8.153,8.153,8.153
			c4.503,0,8.153-3.65,8.153-8.153c0-16.668-18.534-30.069-43.482-32.49v-0.937c0-4.503-3.65-8.153-8.153-8.153
			s-8.153,3.65-8.153,8.153v0.937c-24.948,2.421-43.482,15.822-43.482,32.49c0,23.807,23.52,32.399,43.482,38.013v41.664
			c-15.994-1.944-27.176-9.354-27.176-16.085c0-4.503-3.65-8.153-8.153-8.153s-8.153,3.65-8.153,8.153
			c0,16.668,18.534,30.069,43.482,32.49v0.937c0,4.503,3.65,8.153,8.153,8.153s8.153-3.65,8.153-8.153v-0.937
			c24.948-2.421,43.482-15.822,43.482-32.49C259.805,272.684,236.284,264.093,216.323,258.479z M200.017,253.843
			c-20.08-6.299-27.176-12.023-27.176-20.943c0-6.731,11.182-14.141,27.176-16.085V253.843z M216.323,312.578v-37.028
			c20.08,6.298,27.176,12.023,27.176,20.943C243.499,303.224,232.316,310.634,216.323,312.578z"/>
	</g>
</g>
</svg>
                                        </div>
                                        <div className="sc-feature-text translate" onClick={()=>translateClick('cloud17')}>
                                            <h4 className="feature-title">Ahorro</h4>
                                            <div className="description sc-mb-25">
                                            Variedad de métodos de cobro por servicio.
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </Fade>
                    </div>
                   
                </div>
                    
                </div>
            </section>

        <section className="sc-apps-section-area sc-apps-bottom">
            <div className="container">
        <div className="row">
            <div className="bannerLinks">
            <div className="row">
                <div className="col-lg-8 translate">
                <p  className="bannerText">Utilice nuestro reproductor<span className='bannerTextHighlight'> 3D </span> 
                para ver su contenido en lengua de señas, generado desde <span className='bannerTextHighlight'>helen Cloud</span> .
                </p>

                <a href="https://helenviewer.helenai.com"><img  style={{width:"280px", paddingLeft:"5%"}} src={botonplayer} alt="botonplayer"/></a> 
                <br></br>
                <br></br>                               
                </div>

               
                <div className="col-lg-4">
                    <img src={bannerApps}/>
                </div>

                </div>
            </div>

        </div>
    </div>
    
       
        </section>
        </>
    );
};

export default ServiceFeature;